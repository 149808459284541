// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[10].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../../node_modules/less-loader/dist/cjs.js!../../../../routing/RoutesWrapper/style.m.less");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".Container-container-f8rgT {\n  display: flex;\n  align-items: flex-start;\n  min-height: 100vh;\n  background-color: #ffffff;\n}\n@media print {\n  .Container-container-f8rgT {\n    display: block;\n    opacity: 1;\n  }\n}\n.Container-reveal-3aSxc {\n  opacity: 1;\n}\n.Container-printContainer-qJZ8D {\n  display: block;\n  grid-column: form;\n  max-width: 660px;\n  grid-template-columns: minmax(0, 660px);\n  margin-left: auto;\n  margin-right: auto;\n}\n@media print {\n  .Container-printContainer-qJZ8D {\n    max-width: none;\n  }\n}\n.Container-wide-3mfkY {\n  max-width: 940px;\n  grid-template-columns: minmax(0, 940px);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Container-container-f8rgT " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["content"] + "",
	"reveal": "Container-reveal-3aSxc",
	"printContainer": "Container-printContainer-qJZ8D",
	"wide": "Container-wide-3mfkY"
};
module.exports = exports;
