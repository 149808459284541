import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { compose } from 'lodash/fp';
import image from '../../../../../page/screens/registerPage/onboarding-survey-step-two-highlight.png';
import authorImage from '../../../../../page/screens/registerPage/zapier-portrait.jpg';
import api from '../../../../api';
import Button from '../../../components/Button';
import { Form } from '../../../components/Form';
import { FormErrorMessage } from '../../../components/Form/FormErrorMessage';
import { FormGridRadio } from '../../../components/Form/FormInputs';
import { FormItem } from '../../../components/Form/FormItem';
import RegisterFlowTemplate from '../../../components/RegisterFlowTemplate';
import SaveButton from '../../../components/SaveButton';
import { withAppContext } from '../../../hoc/withAppContext';
import { withPageData } from '../../../hoc/withPageData';
import { goToOnboardingSurveyDone } from '../../../services/GoTo';
import styles from '../styles.m.less';
const BUTTON_LABELS = {
    DISABLED: 'Continue',
    ENABLED: 'Continue',
};
function OnboardingSurveyHris({ adminLinks }) {
    const handleSubmit = async (formValue) => {
        if (formValue.hris) {
            return api.onboardingSettings
                .updateOnboardingSettings({ hris: formValue.hris.name })
                .then(goToOnboardingSurveyDone)
                .catch(goToOnboardingSurveyDone);
        }
    };
    const getIconImages = () => {
        let iconImagesArray = {};
        adminLinks.forEach((option) => {
            let icon;
            try {
                icon = require(`../../../features/Admin/Overview/svg/${option.icon}.svg`);
            }
            catch (e) {
                icon = require(`../../../features/Admin/Overview/svg/default.svg`);
            }
            iconImagesArray = Object.assign(Object.assign({}, iconImagesArray), { [option.icon]: icon });
        });
        return iconImagesArray;
    };
    return (_jsx(RegisterFlowTemplate, { step: "5", imageURL: image, quote: "\u201CSmall Improvements supports our remote working culture because it\u2019s accessible from anywhere, intuitive to use, and easily adaptable to what we need.\u201D", author: "Jenny Bloom", role: "Chief Financial Officer, Zapier", authorImage: authorImage, title: "Integrate with your HRIS", children: _jsx(Form, { onSubmit: handleSubmit, children: ({ submitError, invalid, pristine }) => {
                return (_jsxs(_Fragment, { children: [_jsx(FormItem, { name: "hris", label: "", inputComponent: FormGridRadio, inputProps: {
                                options: adminLinks,
                                urlMapping: getIconImages(),
                            } }), _jsxs("div", { className: styles.formButtonContainer, children: [_jsx(SaveButton, { state: "ENABLED", labels: BUTTON_LABELS, disabled: invalid || pristine }), submitError ? _jsx(FormErrorMessage, { errorMessage: submitError }) : null, _jsx(Button, { kind: "LINK", onClick: () => handleSubmit({ hris: { name: 'other' } }), children: "My HRIS is not listed" })] })] }));
            } }) }));
}
export default compose(withPageData({
    resolve: {
        adminLinks: () => api.admin
            .getIntegrationsLinks()
            .then(data => data.filter((option) => option.category.includes('Automated User Import'))),
    },
}), withAppContext)(OnboardingSurveyHris);
