import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import classNames from 'classnames';
import { IconX } from 'featherico';
import { noop } from 'lodash/fp';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import useIdGen from '../../hooks/useIdGen';
import Button from '../Button';
import SIZES from './sizes';
import styles from './style.m.less';
const TRANSITION_DURATION = 150;
const STATUS = {
    info: styles.statusInfo,
    success: styles.statusSuccess,
    waiting: styles.statusWaiting,
    error: styles.statusError,
};
const asSizeValue = (v) => (typeof v === 'number' ? `${v}px` : v);
function ModalContent(_a) {
    var { withPadding, className = undefined } = _a, rest = __rest(_a, ["withPadding", "className"]);
    return (_jsx("div", Object.assign({ className: classNames({
            [styles.contentInnerPadding]: withPadding,
        }, className), role: "presentation", onClick: event => event.stopPropagation() }, rest)));
}
const parseMinHeight = (minHeight) => {
    if (!minHeight) {
        return 'auto';
    }
    return typeof minHeight === 'number' ? `${minHeight}px` : minHeight;
};
export function ModalTitle({ children = undefined, id = undefined, titleAlignedCenter = undefined, noCloseIcon = undefined, }) {
    const cN = classNames(styles.title, {
        [styles.centered]: titleAlignedCenter,
        [styles.marginWithCloseIcon]: !noCloseIcon,
    });
    return (_jsx("h1", { id: id, className: cN, children: children }));
}
Modal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    noCloseIcon: PropTypes.bool,
    backgroundImage: PropTypes.string,
    backgroundRepeat: PropTypes.string,
    backgroundSize: PropTypes.string,
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.oneOf(Object.keys(SIZES)),
    status: PropTypes.oneOf(Object.keys(STATUS)),
    topAligned: PropTypes.bool,
    title: PropTypes.node,
    titleAlignedCenter: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fullHeight: PropTypes.bool,
    requireExplicitClose: PropTypes.bool,
    className: PropTypes.string,
    withPadding: PropTypes.bool,
};
export default function Modal(_a) {
    var { isOpen, onClose, width, noCloseIcon, backgroundImage, backgroundRepeat, backgroundSize, minHeight, size = 'XL_WIDTH', status, topAligned = false, title, titleAlignedCenter = false, fullScreen = false, fullHeight = false, requireExplicitClose, className, withPadding = true, children } = _a, otherProps = __rest(_a, ["isOpen", "onClose", "width", "noCloseIcon", "backgroundImage", "backgroundRepeat", "backgroundSize", "minHeight", "size", "status", "topAligned", "title", "titleAlignedCenter", "fullScreen", "fullHeight", "requireExplicitClose", "className", "withPadding", "children"]);
    useEffect(() => {
        ReactModal.setAppElement(document.getElementsByTagName('main')[0] || document.body);
    }, []);
    const id = useIdGen();
    const labelId = id('title');
    const [wasOpened, setWasOpened] = useState(isOpen);
    useEffect(() => {
        if (isOpen) {
            setWasOpened(true);
        }
    }, [isOpen]);
    if (!wasOpened) {
        return null;
    }
    const handleClose = (event) => {
        event.stopPropagation();
        if (onClose) {
            onClose();
        }
    };
    const style = {
        overlay: {
            transitionDuration: TRANSITION_DURATION + 'ms',
        },
        content: {
            backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
            backgroundRepeat: backgroundRepeat || 'no-repeat',
            backgroundSize: backgroundSize,
            width: fullScreen ? '100%' : width || asSizeValue(SIZES[size]),
            minHeight: fullScreen ? '100%' : parseMinHeight(minHeight),
        },
    };
    const content = (_jsxs(ModalContent, { withPadding: withPadding, children: [title && (_jsx(ModalTitle, { id: labelId, titleAlignedCenter: titleAlignedCenter, noCloseIcon: noCloseIcon, children: title })), !noCloseIcon && (_jsx(Button, { kind: "PLAIN", className: styles.closeButton, onClick: handleClose, "aria-hidden": true, children: _jsx(IconX, {}) })), _jsx("div", { className: classNames(styles.contentInnerInner, className), children: children })] }));
    const modalProps = Object.assign(Object.assign({}, otherProps), { onRequestClose: requireExplicitClose ? noop : handleClose, closeTimeoutMS: TRANSITION_DURATION, style, aria: {
            labelledby: labelId,
            modal: true,
        }, role: 'dialog', children: content, className: classNames(styles.content, status ? STATUS[status] : undefined), overlayClassName: classNames([styles.overlay], {
            [styles.overlayTopAligned]: topAligned,
            [styles.overlayFullHeight]: fullHeight,
            [styles.overlayBackdrop]: !fullScreen,
        }), isOpen });
    return _jsx(ReactModal, Object.assign({}, modalProps));
}
