import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { noop } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Children, Component } from 'react';
import { divWithClassName } from '../../services/Component';
import styles from './style.m.less';
let idCounter = 0;
function createName() {
    if (idCounter > 100000) {
        idCounter = 0;
    }
    return `panel-radio-button-group-${idCounter++}`;
}
const nameContext = {
    panelRadioButtonGroup: PropTypes.shape({
        name: PropTypes.string,
    }),
};
const selectionContext = {
    panelRadioButtonItem: PropTypes.shape({
        selected: PropTypes.bool,
    }),
};
export class PanelRadioButtonGroup extends Component {
    getChildContext() {
        const name = this.props.name || createName();
        return { panelRadioButtonGroup: { name } };
    }
    render() {
        return divWithClassName('radio-btn-block-items', this.props);
    }
}
PanelRadioButtonGroup.childContextTypes = nameContext;
export class PanelRadioButtonItem extends Component {
    getChildContext() {
        const { selected, disabled } = this.props;
        return { panelRadioButtonItem: { selected, disabled } };
    }
    render() {
        const { style, className, children, selected, onSelect, hideRadio, disabled, dataIntercomTarget } = this.props;
        const props = {
            style,
            className: classNames(className, styles.item, {
                [styles.item_selected]: selected && !disabled,
                'radio-btn-block-item-selected': selected && !hideRadio && !disabled,
                [styles.item_hide_radio]: hideRadio,
                [styles.single_child]: Children.count(children) === 1,
                [styles.item_disabled]: disabled,
            }),
            onClick: disabled ? noop : onSelect,
            'data-intercom-target': dataIntercomTarget,
        };
        return _jsx("div", Object.assign({}, props, { children: children }));
    }
}
PanelRadioButtonItem.childContextTypes = selectionContext;
export function PanelRadioButtonLabel({ style, className, children, hideRadio = false }, { panelRadioButtonGroup: { name }, panelRadioButtonItem: { selected, disabled } }) {
    return (_jsxs("div", { style: style, className: classNames(className, {
            'radio-btn-block-label': !hideRadio,
        }), children: [_jsx("input", { name: name, disabled: disabled, type: "radio", checked: !!selected, className: styles.input, onChange: noop }), _jsx("label", { className: styles.label, children: children })] }));
}
PanelRadioButtonLabel.contextTypes = Object.assign(Object.assign({}, nameContext), selectionContext);
export function PanelRadioButtonBody(props) {
    return divWithClassName(styles.body, props);
}
export function PanelRadioButtonIcon(props) {
    return divWithClassName('radio-btn-block-icon', props);
}
export function PanelRadioButtonDescription(props) {
    return divWithClassName('radio-btn-block-item-description', props);
}
PanelRadioButtonGroup.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    name: PropTypes.string,
};
PanelRadioButtonItem.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
};
PanelRadioButtonItem.defaultProps = {
    disabled: false,
};
PanelRadioButtonLabel.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};
PanelRadioButtonBody.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};
