import { jsx as _jsx } from "react/jsx-runtime";
import api from '../../../../../api';
import LoadingIndicatorFull from '../../../../components/LoadingIndicatorFull';
import { withData } from '../../../../hoc/withData';
import { getCyclesPerYear } from '../../../../services/ReviewCycles';
import { EMAIL_TEMPLATE_TYPES } from '../../constants/email-templates';
import ReviewCycleCreate from '../../lazy';
import { getCreateModel } from '../../services';
function ReviewCycleCreatePage({ users, questionnaireTemplates, emailTemplates, cyclesPerYear }) {
    const model = getCreateModel(users, [], emailTemplates, cyclesPerYear);
    return _jsx(ReviewCycleCreate, { mode: "create", model: model, questionnaireTemplates: questionnaireTemplates });
}
export default withData({
    resolve: {
        users: () => api.usersMedium.getAll(),
        questionnaireTemplates: () => api.reviewQuestionnaire.list(),
        emailTemplates: () => api.emailTemplate.getTemplates(EMAIL_TEMPLATE_TYPES),
        cyclesPerYear: () => getCyclesPerYear(),
    },
    pendingComponent: () => _jsx(LoadingIndicatorFull, {}),
})(ReviewCycleCreatePage);
