import api from '../../../api';
import { goToOnboardingSurvey } from '../GoTo';
export async function showOnboardingFormToFirstFiveUsers(currentUser) {
    if (currentUser.isImpersonated || currentUser.isGuest) {
        return;
    }
    if (currentUser.company.dateCreated < new Date('2024-05-10').getTime()) {
        return;
    }
    const onboardingSettingsCount = await api.onboardingSettings.getCompanyOnboardingSettingsCount();
    if (onboardingSettingsCount >= 5) {
        return;
    }
    else if (onboardingSettingsCount === 0) {
        goToOnboardingSurvey();
    }
    else {
        try {
            await api.onboardingSettings.getOnboardingSettings();
        }
        catch (ex) {
            goToOnboardingSurvey();
        }
    }
}
