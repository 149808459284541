import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import * as React from 'react';
import Modal from '../';
import { GENERAL } from '../../../intercom/intercom-data-targets';
import Button, { KINDS } from '../../Button';
import { DeprecatedButtonContainer } from '../../ButtonContainer';
import SIZES from '../sizes';
import styles from './style.m.less';
export function ConfirmModal({ confirmKind = 'PRIMARY', onClose, onConfirm, onCancel = () => { }, confirmLabel = 'Confirm', cancelLabel = 'Cancel', isOpen = false, title, body, size = 'MD_WIDTH', noCloseIcon = true, autoFocusConfirmButton = true, disabled = false, }) {
    return (_jsxs(Modal, { isOpen: isOpen, size: size, title: title, noCloseIcon: noCloseIcon, className: styles.container, onClose: onClose, children: [_jsx("div", { className: styles.body, children: body }), _jsxs(DeprecatedButtonContainer, { className: styles.buttonContainer, children: [_jsx(Button, { onClick: () => {
                            onCancel();
                            onClose();
                        }, children: cancelLabel }), _jsx(Button, { kind: confirmKind, disabled: disabled, onClick: () => {
                            onConfirm();
                            onClose();
                        }, autoFocus: autoFocusConfirmButton, "data-intercom-target": GENERAL.SHARE_BUTTON_CONFIRM_MODAL, children: confirmLabel })] })] }));
}
ConfirmModal.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    confirmKind: PropTypes.oneOf(KINDS),
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    isOpen: PropTypes.bool,
    title: PropTypes.node,
    body: PropTypes.node,
    size: PropTypes.oneOf(Object.keys(SIZES)),
    noCloseIcon: PropTypes.bool,
    autoFocusConfirmButton: PropTypes.bool,
    disabled: PropTypes.bool,
};
ConfirmModal.defaultProps = {
    isOpen: false,
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    size: 'MD_WIDTH',
    confirmKind: 'PRIMARY',
    noCloseIcon: true,
    autoFocusConfirmButton: true,
};
export default ConfirmModal;
