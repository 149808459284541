import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { DASHBOARD } from '../../../../routes';
import { prependAppPath } from '../../../services/Url';
import { DeprecatedButtonContainer } from '../../ButtonContainer';
import LinkButton from '../../LinkButton';
import styles from './style.m.less';
export const ErrorMessage = ({ illustrationSrc, title, body }) => {
    return (_jsxs("div", { className: styles.container, children: [illustrationSrc && _jsx("img", { src: illustrationSrc, alt: "" }), _jsxs("div", { children: [_jsx("h1", { children: title }), body, _jsx(DeprecatedButtonContainer, { children: _jsx(LinkButton, { kind: "PRIMARY", href: prependAppPath(DASHBOARD), children: "Return Home" }) })] })] }));
};
