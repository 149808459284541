import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Link from '../../../components/Link';
import PillBox from '../../../components/PillBox';
import { getWithCount } from '../../../services/Pluralize';
import styles from './style.m.less';
export function PureNavLink({ title, route, routeSearchParams, icon, isCurrent, isDeactivated, notification, linkClass, titleClass, trackingLabel, statusLabel, dataIntercomTarget, }) {
    const searchParams = routeSearchParams ? '?' + new URLSearchParams(routeSearchParams).toString() : '';
    const areNotificationsShown = notification && notification.count > 0;
    return (_jsx("li", { className: styles.liReset, children: _jsx(Link, { href: route + searchParams, hasTextStyle: false, eventTracking: { category: 'navigation sidebar', action: 'click', label: trackingLabel }, "data-intercom-target": dataIntercomTarget, children: _jsxs("div", { className: classNames(styles.link, linkClass, { [styles.active]: isCurrent }), children: [_jsx("span", { className: classNames(styles.icon, { [styles.deactivated]: isDeactivated }), children: icon }), _jsxs("span", { className: classNames(titleClass, styles.title, { [styles.deactivated]: isDeactivated }), children: [title, areNotificationsShown && (_jsx("span", { className: styles.notification, "aria-label": getWithCount('notification', notification.count), children: _jsx(PillBox, { color: notification.color, value: notification.count }) }))] }), statusLabel && _jsx(PillBox, { color: 'YELLOW', value: statusLabel })] }) }) }));
}
