import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconModule1On1, IconModuleObjectives, IconModulePraise, IconModuleRequestFeedback, IconPlus, IconUserArrowOut, IconUsers, } from 'featherico';
import { forEach } from 'lodash/fp';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ANYTIME_FEEDBACK_REQUEST_CREATE, YOUR_COMPANY_USERS_CREATE } from '../../../../../routes';
import { getAdminRouteForNavigation } from '../../../../../routes-pairs';
import { getActiveMessageProjects } from '../../../../../screens/message/constants/projects';
import { Divider, Dropdown, Option, OptionLink } from '../../../../components/Dropdown';
import MessageAlert from '../../../../components/PopupAlert/MessageAlert';
import { ObjectiveCreatedAlert } from '../../../../components/PopupAlert/ObjectiveAlert';
import PraiseAlert from '../../../../components/PopupAlert/PraiseAlert';
import triggerPopupAlert from '../../../../components/PopupAlert/trigger';
import SuspenseWithErrorModal from '../../../../components/SuspenseWithErrorModal';
import { EventType, createEventBusPublisher } from '../../../../hooks/useEventBus';
import { useBooleanState } from '../../../../hooks/useToggle';
import { getAngularService } from '../../../../services/AngularService';
import { isFeatureEnabledForUser } from '../../../../services/Features';
import r from '../../../../services/Replacement';
import { prependAppPath } from '../../../../services/Url';
import { isAnyAdmin } from '../../../../services/User';
import AnytimeFeedbackTypeSelectModal from '../../../AnytimeFeedback/components/AnytimeFeedbackTypeSelect';
import CreateMeetingModal from '../../../Meetings/CreateMeetingModal/lazy';
import PraiseCreateEditModal from '../../../Praise/components/CreateEditModal/lazy';
import styles from './style.m.less';
const angularServicesGetters = {
    objectiveCreate: () => getAngularService(() => import(
    /* webpackChunkName: 'ObjectiveCreate' */ '../../../../../screens/objective/components/objective-create-button/service'), 'ObjectiveCreateButtonService'),
    messageCreate: () => getAngularService(() => import(
    /* webpackChunkName: 'MessageCreateOld' */ '../../../../../screens/message/components/message-create/dialog-service'), 'MessageCreateDialogService'),
};
const createObjective = () => angularServicesGetters
    .objectiveCreate()
    .then(ObjectiveCreateButtonService => ObjectiveCreateButtonService.fetchRequiredData().then(() => ObjectiveCreateButtonService))
    .then(ObjectiveCreateButtonService => {
    ObjectiveCreateButtonService.create().then((objective) => triggerPopupAlert(_jsx(ObjectiveCreatedAlert, { objective: objective }), 'success', { timeoutMs: null }), () => { } // dialog was closed
    );
});
const createMessageForProject = (project) => () => angularServicesGetters.messageCreate().then(MessageCreateDialogService => {
    MessageCreateDialogService.show(project).dialog.promise.then((message) => triggerPopupAlert(_jsx(MessageAlert, { entityName: project.entityName, project: project, message: message }), 'success', { timeoutMs: null }), () => { } // dialog was closed
    );
});
const usePreload = () => {
    const [preloadTriggered, setPreloadTriggered] = useState(false);
    const onTriggerPreload = useCallback(() => {
        if (!preloadTriggered) {
            setPreloadTriggered(true);
        }
    }, [preloadTriggered]);
    useEffect(() => {
        if (preloadTriggered) {
            forEach(getter => getter(), angularServicesGetters);
        }
    }, [preloadTriggered]);
    return { preloadTriggered, onTriggerPreload };
};
const onPraiseCreateSuccess = (praise) => triggerPopupAlert(_jsx(PraiseAlert, { praise: praise }), 'success', { timeoutMs: null });
const meetingCreatedPublisher = createEventBusPublisher(EventType.MeetingCreated);
const GlobalCreate = ({ user }) => {
    const { preloadTriggered, onTriggerPreload } = usePreload();
    const [praiseModalOpen, openPraiseModal, closePraiseModal] = useBooleanState();
    const [meetingModalOpen, openMeetingModal, closeMeetingModal] = useBooleanState();
    const [anytimeFeedbackModalOpen, openAnytimeFeedbackModal, closeAnytimeFeedbackModal] = useBooleanState();
    const messageProjects = useMemo(() => getActiveMessageProjects(user), [user]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.globalCreate, onMouseOver: onTriggerPreload, children: _jsxs(Dropdown, { kind: "PRIMARY", showChevron: false, label: _jsxs(_Fragment, { children: [_jsx(IconPlus, {}), " ", _jsx("span", { className: "app-header-item-label", children: "Create" })] }), eventTracking: { category: 'navigation header', action: 'click create' }, large: true, children: [isFeatureEnabledForUser('praise', user) && (_jsxs(Option, { onClick: () => {
                                onTriggerPreload();
                                openPraiseModal();
                            }, eventTracking: { category: 'navigation header', action: 'create', label: 'praise' }, children: [_jsx(IconModulePraise, {}), " ", r('praise.header')] })), isFeatureEnabledForUser('meetings', user) && (_jsxs(Option, { onClick: () => {
                                onTriggerPreload();
                                openMeetingModal();
                            }, eventTracking: { category: 'navigation header', action: 'create', label: 'meeting' }, children: [_jsx(IconModule1On1, {}), " ", r('meeting.name')] })), isFeatureEnabledForUser('objective', user) && (_jsxs(Option, { onClick: createObjective, eventTracking: { category: 'navigation header', action: 'create', label: 'objective' }, children: [_jsx(IconModuleObjectives, {}), " ", r('objective.singular.big')] })), isFeatureEnabledForUser('requestFeedback', user) &&
                            (isFeatureEnabledForUser('FEATURE_anytimeFeedbackGive', user) ? (_jsxs(Option, { onClick: () => {
                                    onTriggerPreload();
                                    openAnytimeFeedbackModal();
                                }, eventTracking: {
                                    category: 'navigation header',
                                    action: 'create',
                                    label: 'anytime feedback',
                                }, children: [_jsx(IconUserArrowOut, {}), " ", r('anyfb.name')] })) : (_jsxs(OptionLink, { href: prependAppPath(ANYTIME_FEEDBACK_REQUEST_CREATE), eventTracking: {
                                    category: 'navigation header',
                                    action: 'create',
                                    label: 'feedback request',
                                }, children: [_jsx(IconModuleRequestFeedback, {}), " Feedback Request"] }))), isFeatureEnabledForUser('message', user) &&
                            messageProjects.map(project => (_jsxs(Option, { onClick: createMessageForProject(project), eventTracking: {
                                    category: 'navigation header',
                                    action: 'create',
                                    label: project.entityName.toLocaleLowerCase(),
                                }, children: [_jsx(project.iconComponent, {}), " ", project.entityName] }, project.id))), isAnyAdmin(user) && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(OptionLink, { href: prependAppPath(getAdminRouteForNavigation(YOUR_COMPANY_USERS_CREATE)), eventTracking: { category: 'navigation header', action: 'create', label: 'users' }, children: [_jsx(IconUsers, {}), " Users"] })] }))] }) }), _jsx(SuspenseWithErrorModal, { enableErrorModal: meetingModalOpen, children: preloadTriggered && (_jsx(CreateMeetingModal, { isOpen: meetingModalOpen, onClose: closeMeetingModal, onCreate: meetingCreatedPublisher, isDraft: false })) }), _jsx(SuspenseWithErrorModal, { enableErrorModal: praiseModalOpen, children: preloadTriggered && (_jsx(PraiseCreateEditModal, { isOpen: praiseModalOpen, onClose: closePraiseModal, onSuccess: onPraiseCreateSuccess })) }), _jsx(SuspenseWithErrorModal, { enableErrorModal: anytimeFeedbackModalOpen, children: preloadTriggered && (_jsx(AnytimeFeedbackTypeSelectModal, { isOpen: anytimeFeedbackModalOpen, onClose: closeAnytimeFeedbackModal })) })] }));
};
export default GlobalCreate;
