import { jsx as _jsx } from "react/jsx-runtime";
import { IconActivity, IconAreaChart, IconCompany, IconGrid, IconHome, IconMessageCircle, IconModule1On1, IconModule360, IconModuleAutomations, IconModuleObjectives, IconModulePraise, IconModuleRequestFeedback, IconModuleReviews, IconSettings, IconTeam, } from 'featherico';
import { negate, overSome, stubTrue } from 'lodash/fp';
import * as React from 'react';
import api from '../../../../api';
import * as R from '../../../../routes';
import { NAVIGATION } from '../../../intercom/intercom-data-targets';
import { isFeatureEnabled, isFeatureEnabledForUser } from '../../../services/Features';
import r from '../../../services/Replacement';
import { hasBasicHr, hasManager, isAdmin, isAnyAdmin, isGuest, isHr, isManager } from '../../../services/User';
import { countAdminErrors } from '../../Admin/Overview/service';
import { getRetentionFeatureMenuName } from '../../Retention/wording';
import { showGeneralFeedbackWording } from '../../YourFeedback/services/feedback';
const featureStatus = (...features) => (enabledFeatures) => features.some(feature => enabledFeatures[feature]);
export const employeeLinks = [
    {
        className: '',
        links: [
            {
                title: 'Home',
                route: R.BASE + R.DASHBOARD,
                alsoMatch: [],
                icon: _jsx(IconHome, {}),
                isFeatureEnabled: stubTrue,
                isAvailableTo: negate(isGuest),
                notifier: null,
                titleClass: '',
                trackingLabel: 'home',
            },
            {
                title: r('praise.menu.title'),
                route: R.BASE + R.PRAISE_STREAM,
                alsoMatch: [],
                icon: _jsx(IconModulePraise, {}),
                isFeatureEnabled: featureStatus('praise'),
                isAvailableTo: negate(isGuest),
                notifier: null,
                titleClass: '',
                trackingLabel: 'praise',
                dataIntercomTarget: NAVIGATION.EMPLOYEE_PRAISE,
            },
            {
                title: r('meeting.name.plural'),
                route: R.BASE + R.MEETING_OVERVIEW,
                alsoMatch: [R.BASE + R.MEETING_DEDICATED],
                icon: _jsx(IconModule1On1, {}),
                isFeatureEnabled: featureStatus('meetings'),
                isAvailableTo: user => !user.isGuest && isFeatureEnabledForUser('FEATURE_meetingNewNavigation', user),
                notifier: null,
                titleClass: '',
                trackingLabel: 'meetings',
                dataIntercomTarget: NAVIGATION.EMPLOYEE_MEETINGS,
            },
        ],
    },
    {
        links: [
            {
                title: `Your ${r('meeting.name.plural')}`,
                route: R.BASE + R.MEETING_OVERVIEW,
                alsoMatch: [R.BASE + R.MEETING_DEDICATED],
                icon: _jsx(IconModule1On1, {}),
                isFeatureEnabled: featureStatus('meetings'),
                isAvailableTo: user => !user.isGuest && !isFeatureEnabledForUser('FEATURE_meetingNewNavigation', user),
                notifier: null,
                titleClass: '',
                trackingLabel: 'meetings',
                dataIntercomTarget: NAVIGATION.EMPLOYEE_MEETINGS,
            },
            {
                title: `${r('surveys.menu')}`,
                route: R.BASE + R.YOUR_SURVEYS,
                alsoMatch: [],
                icon: _jsx(IconActivity, {}),
                isFeatureEnabled: f => f.surveys,
                isAvailableTo: user => (user.isGuest ? false : isFeatureEnabledForUser('surveys', user)),
                notifier: null,
                trackingLabel: 'surveys',
                dataIntercomTarget: NAVIGATION.EMPLOYEE_SURVEYS,
            },
            {
                title: `Your ${r('objective.plural.title')}`,
                route: R.BASE + R.OBJECTIVE_BROWSER,
                alsoMatch: [],
                icon: _jsx(IconModuleObjectives, {}),
                isFeatureEnabled: featureStatus('objective'),
                isAvailableTo: negate(isGuest),
                notifier: null,
                titleClass: '',
                trackingLabel: 'objectives',
                dataIntercomTarget: NAVIGATION.EMPLOYEE_OBJECTIVES,
            },
            {
                title: `Your ${showGeneralFeedbackWording ? r('fr.menu.unified.title') : r('fr.menu.title')}`,
                route: R.BASE + R.FEEDBACK_USER,
                alsoMatch: [],
                icon: _jsx(IconModule360, {}),
                isFeatureEnabled: featureStatus('feedback', 'requestFeedback'),
                isAvailableTo: stubTrue,
                notifier: null,
                titleClass: '',
                trackingLabel: '360s',
                dataIntercomTarget: NAVIGATION.EMPLOYEE_FEEDBACK,
            },
            {
                title: `Your ${r('pr.menu.title')}`,
                route: R.BASE + R.YOUR_REVIEWS,
                alsoMatch: ['/appraisalView', '/appraisalDetails'],
                icon: _jsx(IconModuleReviews, {}),
                isFeatureEnabled: featureStatus('review'),
                isAvailableTo: negate(isGuest),
                notifier: null,
                titleClass: '',
                trackingLabel: 'reviews',
                dataIntercomTarget: NAVIGATION.EMPLOYEE_REVIEWS,
            },
        ],
    },
    {
        className: '',
        links: [
            {
                title: 'Your Automations',
                route: R.BASE + R.EMPLOYEE_AUTOMATIONS,
                alsoMatch: [],
                icon: _jsx(IconModuleAutomations, {}),
                isFeatureEnabled: featureStatus('meetings'), // TODO: When we add more automations this needs to check if any actions/triggers are available
                isAvailableTo: u => !isGuest(u) && isFeatureEnabledForUser('FEATURE_automationsEmployee', u),
                notifier: null,
                titleClass: '',
                trackingLabel: 'employeeAutomations',
            },
            {
                title: 'Team',
                route: R.BASE + R.YOUR_TEAM,
                alsoMatch: [],
                icon: _jsx(IconTeam, {}),
                isFeatureEnabled: stubTrue,
                isAvailableTo: u => !isGuest(u) && (isManager(u) || hasManager(u)),
                notifier: null,
                titleClass: '',
                trackingLabel: 'team',
            },
            {
                title: r('company.big'),
                route: R.BASE + R.YOUR_COMPANY,
                alsoMatch: [],
                icon: _jsx(IconCompany, {}),
                isFeatureEnabled: stubTrue,
                isAvailableTo: negate(isGuest),
                notifier: null,
                titleClass: '',
                trackingLabel: 'company',
            },
        ],
    },
];
export const adminLinks = [
    {
        links: [
            {
                title: 'Home',
                route: R.BASE + R.ADMIN_DASHBOARD,
                alsoMatch: [],
                icon: _jsx(IconHome, {}),
                isFeatureEnabled: stubTrue,
                isAvailableTo: hasBasicHr,
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminDashboard',
            },
            {
                title: r('meeting.name.plural'),
                route: R.BASE + R.ADMIN_MEETINGS,
                alsoMatch: [],
                icon: _jsx(IconModule1On1, {}),
                isFeatureEnabled: featureStatus('meetings'),
                isAvailableTo: hasBasicHr,
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminMeetings',
                dataIntercomTarget: NAVIGATION.ADMIN_MEETINGS,
            },
            {
                title: r('objective.plural.title'),
                route: R.BASE + R.ADMIN_OBJECTIVES_SELECT_CYCLE,
                alsoMatch: [],
                icon: _jsx(IconModuleObjectives, {}),
                isFeatureEnabled: featureStatus('objective'),
                isAvailableTo: hasBasicHr,
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminObjectives',
                dataIntercomTarget: NAVIGATION.ADMIN_OBJECTIVES,
            },
            {
                title: r('fr.menu.title'),
                route: R.BASE + R.ADMIN_FEEDBACK_SELECT_CYCLE,
                alsoMatch: [],
                icon: _jsx(IconModule360, {}),
                isFeatureEnabled: featureStatus('feedback'),
                isAvailableTo: hasBasicHr,
                notifier: null,
                titleClass: '',
                trackingLabel: 'admin360s',
                dataIntercomTarget: NAVIGATION.ADMIN_360_FEEDBACK,
            },
            {
                title: r('praise.singular'),
                route: R.BASE + R.ADMIN_PRAISE,
                alsoMatch: [],
                icon: _jsx(IconModulePraise, {}),
                isFeatureEnabled: featureStatus('praise'),
                isAvailableTo: negate(isGuest),
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminPraise',
                dataIntercomTarget: NAVIGATION.ADMIN_PRAISE,
            },
            {
                title: isFeatureEnabled('FEATURE_anytimeFeedbackGive') ? r('anyfb.name') : 'Feedback Requests',
                route: R.BASE + R.ADMIN_FEEDBACK_REQUESTED,
                alsoMatch: [],
                icon: _jsx(IconModuleRequestFeedback, {}),
                isFeatureEnabled: featureStatus('requestFeedback'),
                isAvailableTo: hasBasicHr,
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminRequestedFeedback',
                dataIntercomTarget: NAVIGATION.ADMIN_ANYTIME_FEEDBACK,
            },
            {
                title: r('pr.menu.title'),
                route: R.BASE + R.ADMIN_REVIEWS_SELECT_CYCLE,
                alsoMatch: ['/appraisalView', '/appraisalDetails', R.BASE + R.YOUR_REVIEWS],
                icon: _jsx(IconModuleReviews, {}),
                isFeatureEnabled: featureStatus('review'),
                isAvailableTo: hasBasicHr,
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminReviews',
                dataIntercomTarget: NAVIGATION.ADMIN_REVIEWS,
            },
            {
                title: r('messages.menu.title'),
                route: R.BASE + R.ADMIN_MESSAGES,
                alsoMatch: [],
                icon: _jsx(IconMessageCircle, {}),
                isFeatureEnabled: featureStatus('message'),
                isAvailableTo: hasBasicHr,
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminMessages',
                dataIntercomTarget: NAVIGATION.ADMIN_MESSAGES,
            },
            {
                title: `${r('surveys.plural.big')}`,
                route: R.BASE + R.ADMIN_SURVEYS,
                alsoMatch: [],
                icon: _jsx(IconActivity, {}),
                isFeatureEnabled: f => f.surveys,
                isAvailableTo: user => isFeatureEnabledForUser('surveys', user) && hasBasicHr(user),
                notifier: null,
                trackingLabel: 'adminSurveys',
                dataIntercomTarget: NAVIGATION.ADMIN_SURVEYS,
            },
            {
                title: getRetentionFeatureMenuName(),
                route: R.BASE + R.INSIGHTS,
                alsoMatch: [],
                icon: _jsx(IconAreaChart, {}),
                isFeatureEnabled: f => f.retention,
                isAvailableTo: u => isFeatureEnabledForUser('retention', u) && isHr(u),
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminRetentionInsights',
            },
            {
                title: 'Automations',
                route: R.BASE + R.HR_AUTOMATIONS,
                alsoMatch: [],
                icon: _jsx(IconModuleAutomations, {}),
                isFeatureEnabled: stubTrue,
                isAvailableTo: u => isFeatureEnabledForUser('automations', u) && hasBasicHr(u),
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminAutomations',
            },
        ],
    },
    {
        className: '',
        links: [
            {
                title: 'Directory',
                route: R.BASE + R.ADMIN_USERS_DIRECTORY,
                alsoMatch: [],
                icon: _jsx(IconCompany, {}),
                isFeatureEnabled: stubTrue,
                isAvailableTo: isAnyAdmin,
                notifier: null,
                titleClass: '',
                trackingLabel: 'adminDirectory',
            },
            {
                title: 'Integrations',
                route: R.BASE + R.INTEGRATIONS,
                alsoMatch: [],
                icon: _jsx(IconGrid, {}),
                isFeatureEnabled: stubTrue,
                isAvailableTo: overSome([isHr, isAdmin]),
                notifier: {
                    observe: api.admin.getIntegrationsLinks.createObservable,
                    toNotification: links => ({ count: countAdminErrors(links), color: 'RED' }),
                },
                titleClass: '',
                trackingLabel: 'adminIntegrations',
            },
            {
                title: 'Settings',
                route: R.BASE + R.ADMIN_OVERVIEW,
                alsoMatch: ['/admin'],
                icon: _jsx(IconSettings, {}),
                isFeatureEnabled: stubTrue,
                isAvailableTo: isAnyAdmin,
                notifier: {
                    observe: api.admin.getSettingsLinks.createObservable,
                    toNotification: links => ({ count: countAdminErrors(links), color: 'RED' }),
                },
                titleClass: '',
                trackingLabel: 'adminSettings',
            },
        ],
    },
];
