import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import Button from '../Button';
import { DeprecatedButtonContainer } from '../ButtonContainer';
import { Form } from '../Form';
import { FormItem } from '../Form/FormItem';
import { ModalTitle } from '../Modal/presenter';
import styles from './style.m.less';
const DELETE_KEYWORD = 'DELETE FOREVER';
const keywordValidator = {
    validate: (keyword) => keyword !== DELETE_KEYWORD,
};
export default function DoubleConfirmationDialog({ title, children, onCancel, onDelete, buttonLabel = 'Permanently Delete Now', }) {
    const initialValues = {
        keyword: __DEV__ ? DELETE_KEYWORD : '',
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalTitle, { children: title }), children, _jsxs("p", { children: ["Please type \"", _jsx("strong", { children: DELETE_KEYWORD }), "\" in the box below to proceed with the deletion."] }), _jsx(Form, { onSubmit: onDelete, initialValues: initialValues, children: ({ valid }) => (_jsxs("div", { className: styles.confirmbox, children: [_jsx(FormItem, { name: "keyword", label: "", validators: [keywordValidator], inputProps: {
                                placeholder: `Type "${DELETE_KEYWORD}" to proceed`,
                                autoFocus: true,
                                error: null,
                            } }), _jsxs(DeprecatedButtonContainer, { children: [_jsx(Button, { onClick: onCancel, children: "Cancel" }), _jsx(Button, { type: "submit", kind: "WARNING", disabled: !valid, children: buttonLabel })] })] })) })] }));
}
