import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { mapProps } from '../../hoc/recompose';
import Arrow from '../Arrow';
import { Option as PlainOption, Select as PlainSelect } from './plain';
import styles from './style.m.less';
const createStandardSelected = (mapper, classes) => ({ selected, isOpen, hasError, disabled }) => {
    const controlCn = classNames(styles.control, {
        [styles.isOpen]: isOpen,
        [styles.hasError]: hasError,
        [styles.disabled]: disabled,
    }, classes);
    return (_jsxs("div", { className: controlCn, children: [_jsx("span", { children: mapper(selected) }), _jsx("div", { className: styles.arrow, children: _jsx(Arrow, { dir: "DOWN" }) })] }));
};
const createStandardPlaceholder = () => createStandardSelected(() => '--');
const createStandardOption = (mapper, getKey) => value => {
    return (_jsx(PlainOption, { value: value, className: styles.option, children: mapper(value) }, getKey(value)));
};
const createOptionsFromProps = props => {
    return props.options
        ? Array.prototype.concat.call(props.children || [], props.options.map(createStandardOption(props.optionToText, props.optionToKey || props.optionToText)))
        : props.children;
};
const createPlaceholderFromProps = props => {
    if (props.placeholder === undefined) {
        return createStandardPlaceholder();
    }
    if (typeof props.placeholder === 'string') {
        return createStandardSelected(() => props.placeholder);
    }
    return props.placeholder;
};
const Select = mapProps(props => {
    return Object.assign(Object.assign({}, props), { menuClassName: classNames(styles.menu, props.menuClassName), selectedClassName: classNames(styles.selected, props.selectedClassName), highlightedClassName: styles.highlighted, buttonClassName: classNames({
            [styles.button]: props.kind !== 'PLAIN',
            [styles.buttonWithError]: props.hasError,
            [styles.buttonDefault]: props.kind === 'DEFAULT',
            [styles.buttonTitle]: props.kind === 'TITLE',
            [styles.buttonSmall]: props.isSmall,
            [styles.buttonMaxHeight]: props.buttonMaxHeight,
        }, props.buttonClassName), placeholder: createPlaceholderFromProps(props), selectComponent: props.selectComponent || createStandardSelected(props.optionToText, props.controlClassName), 
        // Below splice because we'd like to override the children spliced in from props.
        children: createOptionsFromProps(props) });
})(PlainSelect);
Select.propTypes = {
    onSelect: PropTypes.func,
    options: PropTypes.array,
    optionToKey: PropTypes.func,
    optionToText: PropTypes.func,
    placeholder: PropTypes.any,
    selected: PropTypes.any,
    buttonClassName: PropTypes.string,
    buttonMaxHeight: PropTypes.bool,
    isSmall: PropTypes.bool,
    kind: PropTypes.oneOf(['DEFAULT', 'TITLE', 'PLAIN']),
    dataIntercomTarget: PropTypes.string,
};
Select.defaultProps = {
    kind: 'DEFAULT',
};
/** @type { React.FC<any> } */
const Option = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return _jsx(PlainOption, Object.assign({ className: classNames(styles.option, className) }, props));
};
export const OptionHeader = ({ name }) => _jsx("div", { className: styles.optionHeader, children: name });
export const OptionDivider = () => _jsx("hr", { className: styles.optionDivider });
export { Arrow, Option, PlainOption, Select, createStandardOption, createStandardPlaceholder, createStandardSelected };
