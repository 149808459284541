import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { shuffle } from 'lodash/fp';
import { useMemo, useState } from 'react';
import image from '../../../../../page/screens/registerPage/onboarding-survey-step-two-highlight.png';
import authorImage from '../../../../../page/screens/registerPage/tomorrow-farms-portrait.jpg';
import api from '../../../../api';
import { Form } from '../../../components/Form';
import { FormErrorMessage } from '../../../components/Form/FormErrorMessage';
import { PanelRadioButtonGroup, PanelRadioButtonItem, PanelRadioButtonLabel, } from '../../../components/PanelRadioButtonGroup';
import RegisterFlowTemplate from '../../../components/RegisterFlowTemplate';
import SaveButton from '../../../components/SaveButton';
import { goToOnboardingSurveyInterestStep } from '../../../services/GoTo';
import styles from '../styles.m.less';
const BUTTON_LABELS = {
    DISABLED: 'Continue',
    ENABLED: 'Continue',
};
const ORIGINS = [
    'Search Engine',
    'Facebook',
    'Instagram',
    'LinkedIn',
    'G2 Reviews',
    'In-person event',
    'Someone referred me',
    'I have used Small Improvements before',
    'HRIS integration',
];
function OnboardingSurveyOrigin() {
    const shuffledOrigins = useMemo(() => shuffle(ORIGINS), []);
    const [selectedOrigin, setSelectedOrigin] = useState(null);
    const handleSubmit = async () => {
        if (selectedOrigin) {
            return api.onboardingSettings
                .updateOnboardingSettings({ origin: selectedOrigin })
                .then(goToOnboardingSurveyInterestStep)
                .catch(goToOnboardingSurveyInterestStep);
        }
    };
    return (_jsx(RegisterFlowTemplate, { step: "3", imageURL: image, quote: "\u201CInstilling a culture of feedback early on is crucial because it helps to establish trust and accountability from day one.\u201D", author: "Eden Seiferheld", role: "People & Operations, Tomorrow Farms", authorImage: authorImage, title: "Where did you first hear about us?", children: _jsx(Form, { onSubmit: handleSubmit, children: ({ submitError }) => {
                return (_jsxs("div", { className: styles.signUpIn, children: [_jsx(PanelRadioButtonGroup, { children: shuffledOrigins.map(option => {
                                return (_jsx(PanelRadioButtonItem, { selected: selectedOrigin === option, onSelect: () => setSelectedOrigin(option), className: styles.radioButtonItem, children: _jsx(PanelRadioButtonLabel, { children: option }) }, option));
                            }) }), _jsxs("div", { className: styles.formButtonContainer, children: [_jsx(SaveButton, { state: 'ENABLED', labels: BUTTON_LABELS, disabled: !selectedOrigin }), submitError ? _jsx(FormErrorMessage, { errorMessage: submitError }) : null] })] }));
            } }) }));
}
export default OnboardingSurveyOrigin;
