import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { camelCase, compose, curry, identity, isEmpty, isEqual, join, keys, map, mapValues, noop, pickBy, reduce, split, } from 'lodash/fp';
import PropTypes from 'prop-types';
import 'quill/dist/quill.snow.css';
import { Component } from 'react';
import { sanitize } from '../../../common/services/sanitizer/service';
import canonicalize from '../../services/canonicalize';
import ensureHtml from '../../services/ensureHtml';
import ReactQuill from './ReactQuill';
import Toolbar from './Toolbar';
import * as CONFIGS from './configs';
import { DESERIALIZERS, MODULES, MODULES_WITH_TOOLBAR_ELEMENTS, SERIALIZERS, isSubmission } from './service';
import styles from './style.m.less';
export const EMPTY_CONTENT = '<p></p>';
const pickKeys = (ks, obj) => reduce((mem, k) => {
    if (obj[k]) {
        mem[k] = obj[k];
    }
    return mem;
}, {}, ks);
const applyFns = curry((fns, t) => reduce((mem, fn) => fn(mem), t, fns));
const collectToolbarElements = reduce((mem, activeModule) => {
    return [...mem, ...(MODULES_WITH_TOOLBAR_ELEMENTS[activeModule] || [])];
}, []);
const propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    conf: PropTypes.string,
    options: PropTypes.object, // pass in custom options (see configs)
    onInit: PropTypes.func,
    onChange: PropTypes.func,
    onSubmitThroughKeyboard: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    tabIndex: PropTypes.number,
    rows: PropTypes.number, // mininum number of rows to display
    autoFocus: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    debounce: PropTypes.number, // ms
    lazyInit: PropTypes.bool,
};
const defaultProps = {
    onInit: noop,
    onChange: noop,
    onFocus: noop,
    onBlur: noop,
    plugins: {},
};
const TOOLBAR_HEIGHT = 36;
const ROW_HEIGHT = 21;
function getHeightStyle(rows) {
    const height = TOOLBAR_HEIGHT + rows * ROW_HEIGHT;
    return { minHeight: `${height}px` };
}
function handleEmptyContent(text) {
    return text === EMPTY_CONTENT ? '' : text;
}
const prependVersionMarker = marker => text => (isEmpty(text) ? text : marker + text);
/**
 * Determine whether a change should be propagated to the outside.
 * Ignores changes that aren't caused by the user (e.g. during initial <div>-to-<p> parsing for
 * any texts which have been added with an older version of Quill).
 * @param {string} source Quill's source of change, e.g. 'user' or 'api'
 */
function shouldInvokeOnChangeHandler(source, hasAnyChangeHappened) {
    const isUserChange = source === 'user';
    const isInitialSetupChange = source === 'api' && !hasAnyChangeHappened;
    return isUserChange || !isInitialSetupChange;
}
const createIdGenerator = () => {
    let id = 0;
    return () => `si-rte-toolbar-id-${id++}`;
};
const generateId = createIdGenerator();
const createToolbarOptions = (id, hide) => {
    if (hide) {
        return false;
    }
    return {
        container: `#${id}`,
    };
};
const getPluginDeserializers = compose(pickBy(identity), mapValues(pluginOptions => pluginOptions.deserializer));
/**
 *
 * @param {Plugin[]} plugins
 * @param {Format[]} formats
 * @returns {string[]} Plugin names
 */
const applyPluginFormats = (plugins, formats) => {
    const pluginNames = keys(plugins);
    return reduce((mem, name) => {
        const mod = MODULES[name];
        if (mod && mod.formats) {
            return [...mem, ...map(f => f.blotName, mod.formats)];
        }
        return mem;
    }, formats, pluginNames);
};
const applyPluginModules = (plugins, modules) => {
    const pluginNames = keys(plugins);
    return reduce((mem, name) => (MODULES[name] ? Object.assign(Object.assign({}, mem), { [name]: plugins[name] }) : mem), modules, pluginNames);
};
const DEFAULT_POSITION = 'top left';
const Callout = ({ position = DEFAULT_POSITION, hasToolbar = false }) => {
    const positionClassName = compose(camelCase, join('_'), split(' '))(position);
    const className = classNames(styles.callout, styles[positionClassName], {
        [styles.hasToolbar]: hasToolbar,
    });
    return _jsx("div", { className: className });
};
class RichTextArea extends Component {
    // About lazyInit
    //
    // Whenever we render this component within an Angular dialog, we need to initialize this
    // component lazily.
    // The toolbar needs the DOM element for it in the document - but angular dialogs compile
    // their template outside of it, which makes initialization of the component fail.
    // We therefore defer the final render here a bit to make things work.
    // The dialog itself is also shown with a slight delay, so the user never sees that we're in
    // fact initializing the RichTextArea lazily.
    constructor(props) {
        super(props);
        // This needs to stay the same Element during the entire lifetime of the instance
        // (can't be created inside render()). A change in the children of the React-Quill Element
        // causes it to "regenerate" (=completely rebuild itself), leading to an endless loop
        // `translate=no` and `class=notranslate` shall prevent translation plugins (there’s no guarantee though)
        this.editorDiv = (_jsx("div", { className: classNames(styles.editor, 'notranslate'), translate: "no" }, "editor"));
        /**
         * @param {string} text changed text coming from Quill
         * @param {object} delta Quill's delta object, currently not being used
         * @param {string} source Quill's source of change, e.g. 'user' or 'api'
         */
        this.handleChange = (text, delta, source) => {
            if (shouldInvokeOnChangeHandler(source, this.hasAnyChangeHappened)) {
                const content = compose(prependVersionMarker(this.versionDirective), handleEmptyContent, t => this.serialize(t, source))(this.getEditor().getSemanticHTML());
                this.props.onChange(content, this.getLength());
            }
            this.hasAnyChangeHappened = true;
        };
        this.handleKeyDown = event => {
            const { onSubmitThroughKeyboard } = this.props;
            if (onSubmitThroughKeyboard && isSubmission(event)) {
                onSubmitThroughKeyboard();
                event.stopPropagation();
                event.preventDefault();
            }
        };
        this.id = generateId();
        this.state = {
            hasFocus: false,
            quill: null,
            canInitializeImmediately: !props.lazyInit,
        };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.hasAnyChangeHappened = false;
        const config = props.config || CONFIGS.FULL;
        this.formats = applyPluginFormats(props.plugins, config.formats);
        const serializers = pickKeys(this.formats, SERIALIZERS);
        const deserializers = Object.assign(Object.assign({}, pickKeys(this.formats, DESERIALIZERS)), getPluginDeserializers(props.plugins));
        this.serialize = applyFns(serializers);
        this.deserialize = applyFns(deserializers);
        const { content, versionDirective } = compose(canonicalize, this.deserialize, ensureHtml)(this.props.value || '');
        this.initialContent = sanitize(content);
        this.versionDirective = versionDirective;
        this.writingAssistant = props.writingAssistant || { enabled: false };
        this.dataIntercomTarget = props.dataIntercomTarget;
    }
    componentDidMount() {
        if (!this.state.canInitializeImmediately) {
            setTimeout(() => {
                this.setState({ canInitializeImmediately: true });
            });
        }
    }
    onFocus() {
        this.setState({ hasFocus: true });
        this.props.onFocus();
    }
    /**
     * This is erroneously triggered when:
     * - clicking the toolbar: https://github.com/quilljs/quill/issues/1290
     * - pasting text: https://github.com/zenoamaro/react-quill/issues/276
     */
    onBlur(...args) {
        this.setState({ hasFocus: false });
        this.props.onBlur(...args);
    }
    getEditorFromRef(ref) {
        if (!this._quill && ref && typeof ref.getEditor === 'function') {
            const quill = ref.getEditor();
            this._quill = quill;
            if (this.props.autoFocus) {
                quill.setSelection(quill.getLength() - 1);
            }
            this.setState({ quill }, () => {
                quill.history.clear(); // Don't include initial value in quill input history
                return this.props.onInit(this.initialContent, this.getLength());
            });
        }
    }
    /**
     *  BEWARE: This method is directly accessed on the instantiated component
     */
    getEditor() {
        return this._quill || null;
    }
    getLength() {
        const editor = this.getEditor();
        return editor ? editor.getLength() - 1 : 0;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const props = ['plugins', 'hideToolbar', 'onSubmitThroughKeyboard', 'config'];
        const hasChanged = prop => !isEqual(nextProps[prop], this.props[prop]);
        if (props.some(hasChanged)) {
            this._modules = null;
        }
    }
    // Memoizing the modules is necessary to prevent regeneration in react-quill
    // (See comment further above, next to the editorDiv initialization)
    getModules() {
        if (!this._modules) {
            const { plugins = {}, hideToolbar, onSubmitThroughKeyboard, config = CONFIGS.FULL } = this.props;
            this._modules = applyPluginModules(plugins, Object.assign(Object.assign(Object.assign({ toolbar: createToolbarOptions(this.id, hideToolbar), clipboard: { matchVisual: false } }, (onSubmitThroughKeyboard ? { submitThroughKeyboardHint: true } : {})), (config.modules || CONFIGS.FULL.modules)), { writingAssistant: this.writingAssistant }));
        }
        return this._modules;
    }
    render() {
        const { className, style, rows, placeholder, disabled, invalid, tabIndex, hideToolbar, callout, config = CONFIGS.FULL, dataIntercomTarget, } = this.props;
        const { hasFocus, quill, canInitializeImmediately } = this.state;
        const containerProps = {
            className: classNames(styles.container, className, {
                [styles.disabled]: disabled,
                [styles.hasToolbar]: !hideToolbar,
                [styles.hasFocus]: hasFocus,
                [styles.invalid]: invalid,
                [styles.hasCalloutTop]: typeof callout === 'string' && callout.startsWith('top'),
                [styles.hasCalloutLeft]: typeof callout === 'string' && callout.startsWith('left'),
            }),
            style: rows ? Object.assign(Object.assign({}, style), getHeightStyle(rows)) : style,
        };
        const toolbarFormats = config.toolbarFormats || config.formats || CONFIGS.FULL.formats;
        const modules = this.getModules();
        const toolbarModules = compose(collectToolbarElements, keys)(modules);
        return (_jsxs("div", Object.assign({}, containerProps, { children: [callout && _jsx(Callout, { disabled: disabled, position: callout, hasToolbar: !hideToolbar }), !hideToolbar && (_jsx(Toolbar, { toolbarId: this.id, quill: quill, moduleComponents: toolbarModules, formats: toolbarFormats, writingAssistant: this.writingAssistant })), canInitializeImmediately && (_jsx(ReactQuill, { ref: ref => this.getEditorFromRef(ref), placeholder: placeholder, readOnly: disabled, tabIndex: tabIndex, theme: "snow", defaultValue: this.initialContent, modules: modules, formats: this.formats, onChange: this.handleChange, onFocus: this.onFocus, onBlur: this.onBlur, onKeyDown: this.handleKeyDown, children: this.editorDiv, dataIntercomTarget: dataIntercomTarget }))] })));
    }
}
RichTextArea.propTypes = propTypes;
RichTextArea.defaultProps = defaultProps;
/**
 *  BEWARE: Some Angular code is directly accessing methods from
 *  the instantiated component!
 */
export default RichTextArea;
