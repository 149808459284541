import { Pricing } from '../../../../types/CustomerDto';
export const PLANS_BY_PRICING = {
    [Pricing.LAUNCH_2024]: {
        id: 8,
        title: 'Launch',
        price: '3',
        description: 'Start your journey with essential HR tools designed to streamline your operations and drive performance.',
        features: [
            'Essential Performance Reviews',
            'Praise',
            'Company Directory',
            'HRIS Integrations',
            'Google Sign-In',
        ],
    },
    [Pricing.GROW_2024]: {
        id: 9,
        title: 'Grow',
        price: '6',
        description: 'Take your team to the next level with advanced feedback and engagement tools for a thriving workplace.',
        featureTitle: 'Everything in Launch',
        features: [
            '360º & Anytime Feedback',
            'Essential Objectives',
            '1:1 Meetings',
            'Pulse Surveys',
            'Slack integration',
        ],
    },
    [Pricing.ELEVATE_2024]: {
        id: 10,
        title: 'Elevate',
        price: '9',
        description: 'Unlock the full potential of your organization with comprehensive analytics and tailored customisation for ultimate efficiency.',
        featureTitle: 'Everything in Grow',
        features: ['All features', 'All Integrations', 'All Customizations', 'Advanced Analytics'],
    },
};
export const PLANS = Object.values(PLANS_BY_PRICING);
