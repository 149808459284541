import { uniq } from 'lodash/fp';
import { Plan } from '../../../../types/CompanyPaymentDto';
import api from '../../../api';
export function isReviewerInCycles(user) {
    return api.reviewCyclesV2
        .getCycles({ mainReviewerId: user.id, contributingReviewerId: user.id })
        .then(cycles => cycles.length > 0);
}
export function getCycleLimit(plan) {
    const cycleLimits = {
        [Plan.Launch]: 5,
    };
    return cycleLimits[plan] || null;
}
export async function getCyclesPerYear() {
    const cycles = await api.reviewCycles.list();
    return cycles.reduce((cyclesPerYear, cycle) => {
        const yearsOfCycle = getYearsOfCycleTimeline(cycle.timeline);
        yearsOfCycle.map(year => {
            if (!cyclesPerYear[year]) {
                cyclesPerYear[year] = [];
            }
            cyclesPerYear[year].push(cycle);
        });
        return cyclesPerYear;
    }, {});
}
/**
 * Determine the years of a cycle’s timeline that should be used for the cycle limit calculation.
 */
export function getYearsOfCycleTimeline(timeline) {
    return uniq([getYear(timeline.processStarts), getYear(timeline.signBy)]);
}
export function getYear(date) {
    return new Date(date).getFullYear();
}
