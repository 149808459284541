import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconBan } from 'featherico';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { filter, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form } from 'react-final-form';
import AlertBox from '../../../../components/AlertBox';
import Button from '../../../../components/Button';
import { DeprecatedButtonContainer } from '../../../../components/ButtonContainer';
import { FormCheckboxContainer, FormSelect } from '../../../../components/Form/FormInputs';
import { FormItem } from '../../../../components/Form/FormItem';
import Interceptor from '../../../../components/Interceptor';
import triggerPopupAlert from '../../../../components/PopupAlert/trigger';
import SaveButton, { DISABLED, ENABLED, SAVED, SAVING } from '../../../../components/SaveButton';
import ConfigWithCaption from './ConfigWithCaption';
import CustomMappingSection from './CustomMappingSection';
import ResetModal from './ResetModal';
import { AUTOMATIC_IMPORTS, DEACTIVATE_ACCOUNTS, ENABLE_REMOVE_BOSS, PASSWORD_EMAIL, PREFERRED_NAME, PROFILE_PICTURES, REACTIVATE_ACCOUNTS, SET_BOSS_OF_NEW_USERS, SYNC_GENDER, UPDATE_BOSSES_OF_EXISTING_USERS, UPDATE_EXISTING_USERS, USE_COMPANY_HIRING_DATE_FORMAT, } from './constants';
import { isNonEmptyAndValid, mkCustomMapping, validateCustomMapping } from './customMapping';
const CSV_DELIMITERS = [
    { value: ',', label: 'Comma (,)' },
    { value: ';', label: 'Semi-colon (;)' },
    { value: '\t', label: 'Tab' },
];
UserImportConfig.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    isPreferredNameAvailable: PropTypes.bool,
    isAutoSendEmailsAvailable: PropTypes.bool,
    isProfilePictureAvailable: PropTypes.bool,
    customMappingConfig: PropTypes.object,
    validationErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hasConnectionDetails: PropTypes.bool.isRequired,
    autoImportHidden: PropTypes.bool,
    customSection: PropTypes.element,
};
function toButtonState({ submitting, pristine, hasValidationErrors }) {
    if (submitting) {
        return SAVING;
    }
    if (hasValidationErrors) {
        return DISABLED;
    }
    if (pristine) {
        return SAVED;
    }
    return ENABLED;
}
export default function UserImportConfig({ disabled, isProfilePictureAvailable, isAutoSendEmailsAvailable = true, isPreferredNameAvailable, customMappingConfig, children, name, config, apiImportConfig, validationErrorMessage, hasConnectionDetails, autoImportHidden, customSection = _jsx(_Fragment, {}), }) {
    const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
    const onSubmit = async (updatedConfig) => {
        var _a, _b, _c, _d, _e, _f;
        try {
            await apiImportConfig.saveConfig(Object.assign(Object.assign({}, config), { customMappings: filter(isNonEmptyAndValid, updatedConfig.customMappings), systemSpecificProperties: updatedConfig.systemSpecificProperties, useExternalSystemsProfilePictures: (_a = updatedConfig.useExternalSystemsProfilePictures) !== null && _a !== void 0 ? _a : false, autoSendEmailsOnAdd: (_b = updatedConfig.autoSendEmailsOnAdd) !== null && _b !== void 0 ? _b : false, mapNicknames: (_c = updatedConfig.mapNicknames) !== null && _c !== void 0 ? _c : false, deactivateMissingUsers: (_d = updatedConfig.deactivateMissingUsers) !== null && _d !== void 0 ? _d : false, reactivateAccounts: (_e = updatedConfig.reactivateAccounts) !== null && _e !== void 0 ? _e : false, synchronizationInterval: updatedConfig.automaticImportsEnabled ? 'EVERY_TWO_HOURS' : 'NEVER', syncGender: updatedConfig.syncGender, customMappingKeepOriginalValueIfEmpty: updatedConfig.customMappingKeepOriginalValueIfEmpty, sftpConfig: updatedConfig.sftpConfig, enableRemoveBoss: (_f = updatedConfig.enableRemoveBoss) !== null && _f !== void 0 ? _f : false, updateExistingUsers: updatedConfig.updateExistingUsers, updateBossesOfExistingUsers: updatedConfig.updateBossesOfExistingUsers, setBossesOfNewUsers: updatedConfig.setBossesOfNewUsers }));
        }
        catch (error) {
            return {
                [FORM_ERROR]: toErrorMessage(error),
            };
        }
    };
    const toErrorMessage = error => {
        var _a, _b;
        if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === 'Invalid credentials') {
            return validationErrorMessage;
        }
        else if (typeof ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === 'string') {
            return error.response.data;
        }
        return 'Saving failed.';
    };
    const onReset = async (id, company) => {
        try {
            await apiImportConfig.resetConfig(id, company);
            setShowResetConfirmationModal(false);
        }
        catch (error) {
            setShowResetConfirmationModal(false);
            triggerPopupAlert('Sorry an error occurred while disabling.', 'error');
            return { [FORM_ERROR]: 'An error occurred while disabling the configuration.' };
        }
    };
    const isAutoImportDisabled = config.synchronizingSystem &&
        config.synchronizingSystem.toUpperCase() !== config.externalUserSystem.toUpperCase();
    const customMappings = config.customMappings.length > 0 ? config.customMappings : [mkCustomMapping()];
    return (_jsx(Form, { onSubmit: onSubmit, initialValues: {
            customMappings,
            systemSpecificProperties: Object.assign(Object.assign({}, config.systemSpecificProperties), { csvDelimiter: name === 'CSV' ? config.systemSpecificProperties.csvDelimiter || ',' : undefined }),
            useExternalSystemsProfilePictures: config.useExternalSystemsProfilePictures,
            autoSendEmailsOnAdd: config.autoSendEmailsOnAdd,
            mapNicknames: config.mapNicknames,
            deactivateMissingUsers: config.deactivateMissingUsers,
            reactivateAccounts: config.reactivateAccounts,
            automaticImportsEnabled: config.synchronizationInterval === 'EVERY_TWO_HOURS',
            syncGender: config.syncGender,
            customMappingKeepOriginalValueIfEmpty: config.customMappingKeepOriginalValueIfEmpty,
            sftpConfig: config.sftpConfig,
            enableRemoveBoss: config.enableRemoveBoss,
            updateExistingUsers: config.updateExistingUsers,
            updateBossesOfExistingUsers: config.updateBossesOfExistingUsers,
            setBossesOfNewUsers: config.setBossesOfNewUsers,
        }, mutators: Object.assign({}, arrayMutators), validate: values => ({
            customMappings: map(validateCustomMapping, values.customMappings),
        }), children: ({ form, handleSubmit, submitting, pristine, submitError, dirtySinceLastSubmit, hasValidationErrors }) => (_jsxs("form", { onSubmit: handleSubmit, className: "admin-config", children: [submitError && !dirtySinceLastSubmit && _jsx(AlertBox, { colour: "RED", children: submitError }), _jsx(Interceptor, { if: !pristine }), _jsxs("fieldset", { disabled: disabled, children: [children, !autoImportHidden && (_jsx(ConfigWithCaption, { name: "automaticImportsEnabled", caption: AUTOMATIC_IMPORTS.caption(name), label: AUTOMATIC_IMPORTS.label, isDisabled: isAutoImportDisabled, isToggle: true })), _jsx(AccountsSection, { name: name, isProfilePictureAvailable: isProfilePictureAvailable, isAutoSendEmailsAvailable: isAutoSendEmailsAvailable, isPreferredNameAvailable: isPreferredNameAvailable }), customSection, _jsx(ImportSection, { name: name }), (customMappingConfig === null || customMappingConfig === void 0 ? void 0 : customMappingConfig.enabled) && (_jsx(CustomMappingSection, { name: name, config: customMappingConfig })), _jsx("section", { children: _jsxs(DeprecatedButtonContainer, { children: [hasConnectionDetails && (_jsxs(Button, { onClick: () => setShowResetConfirmationModal(true), children: [_jsx(IconBan, {}), " Disable Integration"] })), _jsx(SaveButton, { state: toButtonState({
                                            submitting,
                                            hasValidationErrors,
                                            pristine,
                                        }) })] }) })] }), _jsx(ResetModal, { isOpen: showResetConfirmationModal, onDelete: () => onReset(config.id, config.companyId).then(() => {
                        form.restart();
                    }), onCancel: () => setShowResetConfirmationModal(false), name: name })] })) }));
}
function AccountsSection({ name, isProfilePictureAvailable, isAutoSendEmailsAvailable, isPreferredNameAvailable }) {
    if (!(isProfilePictureAvailable || isAutoSendEmailsAvailable || isPreferredNameAvailable)) {
        return null;
    }
    return (_jsxs("div", { children: [_jsx("h5", { children: "Accounts" }), _jsxs(FormCheckboxContainer, { children: [isProfilePictureAvailable && (_jsx(ConfigWithCaption, { name: "useExternalSystemsProfilePictures", caption: PROFILE_PICTURES.caption(name), label: PROFILE_PICTURES.label(name) })), isAutoSendEmailsAvailable && (_jsx(ConfigWithCaption, { name: "autoSendEmailsOnAdd", label: PASSWORD_EMAIL.label })), isPreferredNameAvailable && (_jsx(ConfigWithCaption, { name: "mapNicknames", caption: PREFERRED_NAME.caption(name), label: PREFERRED_NAME.label }))] })] }));
}
function ImportSection({ name }) {
    return (_jsxs("div", { children: [_jsx("h5", { children: "User Import" }), _jsxs(FormCheckboxContainer, { children: [name === 'Namely' && (_jsx(ConfigWithCaption, { name: "syncGender", caption: SYNC_GENDER.caption(name), label: SYNC_GENDER.label })), name === 'CSV' && (_jsxs(_Fragment, { children: [_jsx(ConfigWithCaption, { name: "updateExistingUsers", caption: UPDATE_EXISTING_USERS.caption(name), label: UPDATE_EXISTING_USERS.label }), _jsx(ConfigWithCaption, { name: "updateBossesOfExistingUsers", caption: UPDATE_BOSSES_OF_EXISTING_USERS.caption(name), label: UPDATE_BOSSES_OF_EXISTING_USERS.label }), _jsx(ConfigWithCaption, { name: "setBossesOfNewUsers", caption: SET_BOSS_OF_NEW_USERS.caption(name), label: SET_BOSS_OF_NEW_USERS.label }), _jsx(ConfigWithCaption, { name: "systemSpecificProperties.csvUseCompanyFormatForHiringDate", caption: USE_COMPANY_HIRING_DATE_FORMAT.caption(name), label: USE_COMPANY_HIRING_DATE_FORMAT.label })] })), _jsx(ConfigWithCaption, { name: "deactivateMissingUsers", caption: DEACTIVATE_ACCOUNTS.caption(name), label: DEACTIVATE_ACCOUNTS.label }), _jsx(ConfigWithCaption, { name: "reactivateAccounts", caption: REACTIVATE_ACCOUNTS.caption(name), label: REACTIVATE_ACCOUNTS.label }), _jsx(ConfigWithCaption, { name: "enableRemoveBoss", caption: ENABLE_REMOVE_BOSS.caption(name), label: ENABLE_REMOVE_BOSS.label }), name === 'CSV' && (_jsxs("section", { children: [_jsx("div", { children: _jsx(FormItem, { name: "systemSpecificProperties.csvDelimiter", type: "select", label: "CSV Delimiter", inputComponent: FormSelect, inputProps: {
                                        optionToText: option => CSV_DELIMITERS.find(o => o.value === option).label,
                                        options: CSV_DELIMITERS.map(d => d.value),
                                    } }) }), _jsx("aside", {})] }))] })] }));
}
