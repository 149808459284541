import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconCheck } from 'featherico';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { createElement, useCallback, useEffect, useState } from 'react';
import { Status } from '../../../../types/SparkMetaDto';
import { useAppContext } from '../../hooks/useAppContext';
import { useIsVisible } from '../../hooks/useIsVisible';
import Button from '../Button';
import { DeprecatedButtonContainer } from '../ButtonContainer';
import Modal from '../Modal';
import { MultiModal, MultiModalPage } from '../MultiModal';
import RichText from '../RichText';
import UiPopover from '../UiPopover';
import SPARK_CONTENT, { BodyType, ComponentType, } from './content';
import * as service from './service';
import styles from './style.m.less';
// These flags can be used for debugging (always show sparks)
// and as failsafe to disable them altogether
const DISABLED = false;
function isActiveSpark(spark, status, user) {
    // Always show sparks to small improvements users, otherwise we tend to forget about them.
    // (Exception: autorun-sparks, because that would be too annoying. They should be rare anyway.)
    return (user.isSmallImprovements && !spark.autorun) || Status.ACTIVE === status;
}
export default function Spark({ story, type, description, direction, onVisit }) {
    const spark = (SPARK_CONTENT[story] || {})[type];
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState();
    const appContext = useAppContext();
    useEffect(() => {
        if (!spark || DISABLED) {
            return;
        }
        if (!service.isSparkInTimeRange(spark, Date.now())) {
            console.warn(`Spark '${story} ${type}' is outside of configured time range`); // eslint-disable-line no-console
            return;
        }
        if (spark.persistent) {
            setOpen(!!spark.autorun);
            setStatus(Status.ACTIVE);
        }
        else {
            service.visit(story, type, spark.visibility).then(meta => {
                onVisit === null || onVisit === void 0 ? void 0 : onVisit(meta);
                setOpen(!!spark.autorun);
                setStatus(meta.status);
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleDismiss = useCallback(() => {
        if (!spark.persistent) {
            service.dismiss(story, type);
        }
        setOpen(false);
        setStatus(Status.INACTIVE);
    }, [story, type]); // eslint-disable-line react-hooks/exhaustive-deps
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    if (DISABLED || !spark || !isActiveSpark(spark, status, appContext.user)) {
        return null;
    }
    if (spark.componentType === ComponentType.INLINE && spark.containerless && spark.autorun) {
        if (!open) {
            return null;
        }
        return (_jsx(RevealedSpark, { spark: spark, direction: direction, onClose: handleDismiss, onDismiss: handleDismiss, label: description }));
    }
    return (_jsxs("div", { className: classNames(styles.spark_container, 'no-print'), children: [spark.autorun ? null : _jsx(GlowingSpark, { onOpen: handleOpen, description: description }), open && (_jsx(RevealedSpark, { spark: spark, direction: direction, onClose: spark.autorun ? handleDismiss : handleClose, onDismiss: handleDismiss, label: description }))] }));
}
Spark.proptypes = {
    type: PropTypes.string.isRequired,
    story: PropTypes.string.isRequired,
    description: PropTypes.string,
    direction: PropTypes.string,
};
function GlowingSpark({ onOpen, description }) {
    const { isVisible, ref } = useIsVisible();
    const buttonClasses = classNames({
        [styles.spark]: true,
        [styles.sparkVisible]: isVisible,
        [styles.sparkWithDescription]: description,
    });
    const outerGlowClasses = classNames({
        [styles.glowing_outer]: true,
        [styles.glowingWithDescription_outer]: description,
    });
    const innerGlowClasses = classNames({
        [styles.glowing_inner]: true,
        [styles.glowingWithDescription_inner]: description,
    });
    return (_jsxs("button", { ref: ref, className: buttonClasses, type: "button", onClick: onOpen, children: [_jsx("div", { className: outerGlowClasses }), _jsx("div", { className: innerGlowClasses }), _jsx("div", { className: styles.description, children: description })] }));
}
function RevealedSpark(props) {
    switch (props.spark.componentType) {
        case ComponentType.POPOVER:
            return (_jsx(RevealedPopoverSpark, { spark: props.spark, direction: props.direction, onClose: props.onClose, onDismiss: props.onDismiss }));
        case ComponentType.MULTI_MODAL:
            return _jsx(RevealedMultiModalSpark, { spark: props.spark, onDismiss: props.onDismiss, onClose: props.onClose });
        case ComponentType.MODAL:
            return _jsx(RevealedModalSpark, { spark: props.spark, onDismiss: props.onDismiss, onClose: props.onClose });
        case ComponentType.INLINE:
            return (_jsx(RevealedInlineSpark, { spark: props.spark, onDismiss: props.onDismiss, onClose: props.onClose, label: props.label }));
        default:
            return null;
    }
}
function RevealedMultiModalSpark({ spark, onClose, onDismiss }) {
    const { title, body, modalWidth, modalHeight } = spark;
    const { backgroundImage, steps } = body;
    const props = {
        isOpen: true,
        title,
        onClose: onClose,
        onDone: onDismiss,
        doneLabel: _jsx(GotIt, {}),
        height: modalHeight || 300,
        size: modalWidth,
        backgroundImage,
        backgroundSize: 'cover',
    };
    return (_jsx(MultiModal, Object.assign({}, props, { children: map(steps, (step, i) => (_jsx(MultiModalPage, { children: renderPayload(step) }, i))) })));
}
function RevealedModalSpark({ spark: { title, body }, onClose, onDismiss }) {
    const { backgroundImage, content } = body;
    const modalProps = {
        isOpen: true,
        title,
        onClose,
        width: 600,
        height: 300,
        backgroundImage,
    };
    return (_jsxs(Modal, Object.assign({}, modalProps, { children: [renderPayload(content), _jsx(DeprecatedButtonContainer, { children: _jsx(Button, { kind: "PRIMARY", onClick: onDismiss, autoFocus: true, children: _jsx(GotIt, {}) }) })] })));
}
function RevealedPopoverSpark({ spark, direction = 'bottom--left', onClose, onDismiss }) {
    return (_jsx(UiPopover, { direction: direction, onFocusLost: onClose, children: _jsxs("div", { className: styles.content, children: [spark.title && (_jsx("div", { children: _jsx("h3", { className: styles.title, children: spark.title }) })), _jsx("div", { children: renderPayload(spark.body) }), _jsx(DeprecatedButtonContainer, { children: _jsx(Button, { kind: "PRIMARY", isSmall: true, onClick: onDismiss, autoFocus: true, children: _jsx(GotIt, {}) }) })] }) }));
}
function RevealedInlineSpark(props) {
    const { spark: { body: SparkComponent }, onDismiss, onClose, label, } = props;
    return _jsx(SparkComponent, { onDismiss: onDismiss, onClose: onClose, label: label });
}
function GotIt() {
    return (_jsxs("span", { children: ["Got it ", _jsx(IconCheck, {})] }));
}
function renderPayload(items) {
    return map(items, (item, key) => (_jsx("div", { className: styles.body, children: _jsx(Body, { item: item }) }, key)));
}
function Body({ item }) {
    switch (item.type) {
        case BodyType.TEXT:
            return _jsx(RichText, { value: item.payload.text, sanitize: false, className: styles.text });
        case BodyType.IMAGE:
            return (_jsx("div", { children: _jsx("img", { src: item.payload.url, alt: "" }) }));
        case BodyType.VIDEO:
            return (_jsx("div", { children: _jsx("iframe", { src: item.payload.url, allowFullScreen: true }) }));
        case BodyType.COMPONENT:
            return createElement(item.payload.component);
        default:
            return null;
    }
}
