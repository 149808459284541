/**
 * Create LegacyQuestionniare from ReviewQuestionnaire.
 */
const fromGraph = (graph) => ({
    enabled: true,
    requiredForManager: graph.reviewerAnswerRequired,
    requiredForReviewee: graph.revieweeAnswerRequired,
    confidentialGraph: graph.isConfidential,
    xAxis: {
        description: graph.graphPayload.xDescription,
        high: graph.graphPayload.xHigh,
        label: graph.graphPayload.xLabel,
        low: graph.graphPayload.xLow,
    },
    yAxis: {
        description: graph.graphPayload.yDescription,
        high: graph.graphPayload.yHigh,
        label: graph.graphPayload.yLabel,
        low: graph.graphPayload.yLow,
    },
});
const fromHeading = (heading, index) => ({
    id: `h${index}`,
    title: heading.headingPayload.title,
    description: heading.headingPayload.description,
    questionType: 'HEADING',
    type: 'ReviewHeading',
    manager: {},
    reviewee: {},
});
const fromAssessmentRatingToOverallRating = (assessmentRating, index) => ({
    code: assessmentRating.code,
    description: assessmentRating.description || '',
    label: assessmentRating.name,
    value: index, // @todo Right?
});
const fromOverallRating = (overallRating) => ({
    confidential: overallRating.overallRatingPayload.confidential,
    enabled: true,
    enabledForManager: overallRating.enabledForManager,
    enabledForReviewee: overallRating.enabledForReviewee,
    ratings: overallRating.overallRatingPayload.ratings.map(fromAssessmentRatingToOverallRating),
    requiredForReviewee: overallRating.revieweeAnswerRequired,
    requiredForManager: overallRating.reviewerAnswerRequired,
});
const fromSecondaryOverallRating = (overallRating) => ({
    enabled: true,
    enabledForManager: overallRating.enabledForManager,
    enabledForReviewee: overallRating.enabledForReviewee,
    ratings: overallRating.overallRatingPayload.ratings.map(fromAssessmentRatingToOverallRating),
    requiredForReviewee: overallRating.revieweeAnswerRequired,
    requiredForManager: overallRating.reviewerAnswerRequired,
});
const fromAssessmentRating = (assessmentRating) => ({
    id: assessmentRating.code,
    text: assessmentRating.name,
});
const fromAssessmentCheckboxesQuestion = (assessmentQuestion) => ({
    allowComment: assessmentQuestion.allowComment,
    question: assessmentQuestion.description,
    scale: assessmentQuestion.ratings.map(fromAssessmentRating),
});
const fromAssessmentDropdownQuestion = (assessmentQuestion) => ({
    allowComment: assessmentQuestion.allowComment,
    question: assessmentQuestion.description,
    ratings: assessmentQuestion.ratings.map(fromAssessmentRating),
});
const fromAssessmentScalesQuestion = (assessmentQuestion) => ({
    allowComment: assessmentQuestion.allowComment,
    question: assessmentQuestion.description,
    scale: assessmentQuestion.ratings.map(fromAssessmentRating),
});
const fromAssessmentSliderQuestion = (assessmentQuestion) => ({
    allowComment: assessmentQuestion.allowComment,
    question: assessmentQuestion.description,
});
const fromAssessmentTextQuestion = (assessmentQuestion) => ({
    question: assessmentQuestion.description,
    ratings: [],
    ratingsEnabled: false,
});
const fromAssessmentTextWithRatingQuestion = (assessmentQuestion) => ({
    question: assessmentQuestion.description,
    ratings: assessmentQuestion.ratings.map(fromAssessmentRating),
    ratingsEnabled: true,
});
const fromQuestion = (question, index) => {
    var _a, _b;
    const revieweeQuestionType = (_a = question.revieweePayload) === null || _a === void 0 ? void 0 : _a._tag;
    const reviewerQuestionType = (_b = question.reviewerPayload) === null || _b === void 0 ? void 0 : _b._tag;
    const payloadType = (revieweeQuestionType || reviewerQuestionType);
    switch (payloadType) {
        case 'CHECKBOXES':
            return {
                id: `q${index}`,
                manager: fromAssessmentCheckboxesQuestion(question.reviewerPayload),
                reviewee: fromAssessmentCheckboxesQuestion(question.revieweePayload),
                confidentialTopic: question.isConfidential,
                requiredForManager: question.reviewerPayload.isRequired,
                requiredForReviewee: question.revieweePayload.isRequired,
                questionType: 'CHECKBOXES',
                type: 'ReviewCheckboxes',
            };
        case 'DROPDOWN':
            return {
                id: `q${index}`,
                manager: fromAssessmentDropdownQuestion(question.reviewerPayload),
                reviewee: fromAssessmentDropdownQuestion(question.revieweePayload),
                confidentialTopic: question.isConfidential,
                requiredForManager: question.reviewerPayload.isRequired,
                requiredForReviewee: question.revieweePayload.isRequired,
                questionType: 'DROPDOWN',
                type: 'ReviewDropdown',
            };
        case 'SCALES':
            return {
                id: `q${index}`,
                manager: fromAssessmentScalesQuestion(question.reviewerPayload),
                reviewee: fromAssessmentScalesQuestion(question.revieweePayload),
                confidentialTopic: question.isConfidential,
                requiredForManager: question.reviewerPayload.isRequired,
                requiredForReviewee: question.revieweePayload.isRequired,
                questionType: 'SCALES',
                type: 'ReviewScale',
            };
        case 'SLIDER':
            return {
                id: `q${index}`,
                manager: fromAssessmentSliderQuestion(question.reviewerPayload),
                reviewee: fromAssessmentSliderQuestion(question.revieweePayload),
                confidentialTopic: question.isConfidential,
                requiredForManager: question.reviewerPayload.isRequired,
                requiredForReviewee: question.revieweePayload.isRequired,
                sliderLabels: question.reviewerPayload.sliderLabels,
                sliderSteps: question.reviewerPayload.sliderSteps,
                questionType: 'SLIDER',
                type: 'ReviewSlider',
            };
        case 'TEXT':
        case 'TEXT_WITH_RATING':
            return {
                id: `q${index}`,
                manager: reviewerQuestionType === 'TEXT_WITH_RATING'
                    ? fromAssessmentTextWithRatingQuestion(question.reviewerPayload)
                    : fromAssessmentTextQuestion(question.reviewerPayload),
                reviewee: revieweeQuestionType === 'TEXT_WITH_RATING'
                    ? fromAssessmentTextWithRatingQuestion(question.revieweePayload)
                    : fromAssessmentTextQuestion(question.revieweePayload),
                confidentialTopic: question.isConfidential,
                requiredForManager: question.reviewerPayload.isRequired,
                requiredForReviewee: question.revieweePayload.isRequired,
                questionType: 'TEXT',
                type: 'ReviewQuestion',
            };
    }
};
const fromSection = (section, index) => {
    switch (section._tag) {
        case 'graph':
            return { type: 'graph', res: fromGraph(section) };
        case 'heading':
            return { type: 'heading', res: fromHeading(section, index) };
        case 'overallRating':
            return { type: 'overallRating', res: fromOverallRating(section) };
        case 'secondaryOverallRating':
            return {
                type: 'secondaryOverallRating',
                res: fromSecondaryOverallRating(section),
            };
        case 'question':
            return { type: 'question', res: fromQuestion(section, index) };
    }
};
export const fromReviewQuestionnaire = (defaultQuestionnaire) => (reviewQuestionnaire) => {
    var _a, _b, _c;
    const sections = reviewQuestionnaire.sections.map(fromSection);
    const graph = (((_a = sections.find(x => x.type === 'graph')) === null || _a === void 0 ? void 0 : _a.res) ||
        defaultQuestionnaire.graph);
    const overallRating = (((_b = sections.find(x => x.type === 'overallRating')) === null || _b === void 0 ? void 0 : _b.res) ||
        defaultQuestionnaire.overallRatings);
    const secondaryOverallRating = (((_c = sections.find(x => x.type === 'secondaryOverallRating')) === null || _c === void 0 ? void 0 : _c.res) ||
        defaultQuestionnaire.secondaryOverallRatings);
    const legacySections = sections
        .filter(x => x.type === 'question' || x.type === 'heading')
        .map(x => x.res);
    return [
        {
            name: reviewQuestionnaire.name,
            description: reviewQuestionnaire.description,
            order: reviewQuestionnaire.order,
        },
        Object.assign(Object.assign({}, defaultQuestionnaire), { graph, overallRatings: overallRating, secondaryOverallRatings: secondaryOverallRating, sections: legacySections }),
    ];
};
