import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconModuleRequestFeedback, IconUserArrowOut } from 'featherico';
import { ANYTIME_FEEDBACK_GIVE_CREATE, ANYTIME_FEEDBACK_REQUEST_CREATE } from '../../../../../routes';
import Modal from '../../../../components/Modal';
import { SelectButton } from '../../../../components/SelectButton';
import { TOUR_EMPLOYEE_ANYTIME_FEEDBACK } from '../../../../intercom/intercom-data-targets';
import styles from './style.m.less';
export default function AnytimeFeedbackTypeSelectModal({ isOpen, onClose, }) {
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, children: _jsxs("div", { className: styles.container, children: [_jsx(SelectButton, { route: ANYTIME_FEEDBACK_REQUEST_CREATE, onClose: onClose, title: "Request Feedback", description: "Request feedback from team members about yourself, a specific topic or another team member's performance.", icon: IconModuleRequestFeedback, color: "SALMON" }), _jsx(SelectButton, { route: ANYTIME_FEEDBACK_GIVE_CREATE, onClose: onClose, title: "Give Feedback", description: "Proactively give feedback to team members, about a specific topic or another team member's\n                    performance.", icon: IconUserArrowOut, color: "TURQUOISE", "data-intercom-target": TOUR_EMPLOYEE_ANYTIME_FEEDBACK.BUTTON_GIVE_FEEDBACK })] }) }));
}
