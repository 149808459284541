import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { startCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import { BASE, DASHBOARD } from '../../../../../../routes';
import AdminHelpList from '../../../../../components/AdminHelpList';
import ApiWarning from '../../../../../components/ApiWarning';
import Button from '../../../../../components/Button';
import { DeprecatedButtonContainer } from '../../../../../components/ButtonContainer';
import { WithModal } from '../../../../../hoc/withModal';
import { getDefaultNoReviewerErrorWording } from '../../../services/wording';
import styles from './style.m.less';
NoAssessment.propTypes = {
    message: PropTypes.node,
    adminsToContact: PropTypes.array,
    showHomeButton: PropTypes.bool,
};
NoAssessment.defaultProps = {
    message: '',
    adminsToContact: [],
    showHomeButton: true,
};
export default function NoAssessment({ message, adminsToContact, showHomeButton }) {
    const errorMessage = message ? message : getDefaultNoReviewerErrorWording();
    const AdminsModal = () => (_jsx(WithModal, { size: "LG_WIDTH", renderModalContent: () => (_jsx(AdminHelpList, { adminIds: adminsToContact, children: adminWording => _jsx("h1", { children: startCase(adminWording) }) })), children: ({ openModal }) => _jsx(Button, { onClick: openModal, children: "View Admins" }) }));
    return (_jsx(ApiWarning, { children: _jsxs("div", { className: styles.noAssessment, children: [_jsx("p", { children: errorMessage }), showHomeButton && (_jsxs(DeprecatedButtonContainer, { children: [adminsToContact.length !== 0 && _jsx(AdminsModal, {}), _jsx("a", { href: BASE + DASHBOARD, className: "button save", children: "Home" })] }))] }) }));
}
