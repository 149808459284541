import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { defer } from 'lodash/fp';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Router } from 'react-router';
import { prependAppPathToEmbbeddedWicketPages } from '../react/components/Link/service';
import AppHeader from '../react/features/AppHeader';
import ClippyTrigger from '../react/features/Clippy/components/Trigger/lazy';
import CustomBackdrop from '../react/features/CustomBackdrop';
import HeaderBroadcast from '../react/features/HeaderBroadcast';
import { LicenseWillExpireWarning, TrialExpiredWarning } from '../react/features/LicenseExpiredWarnings';
import Navigation from '../react/features/Navigation';
import { TestDriveModeContainer } from '../react/features/TestDriveMode';
import { useCompanyColorCSSVariable } from '../react/hooks/useCompanyColorCSSVariable';
import { TOUR_INTRO } from '../react/intercom/intercom-data-targets';
import * as keyEventStackService from '../react/services/KeyEventStack';
import AppRoutes from '../routing/Routes';
import history, { isPageReloadScheduled } from '../routing/history';
const getAnchor = (el) => 
// eslint-disable-next-line no-undef
el instanceof HTMLAnchorElement ? el : el.parentNode && getAnchor(el.parentNode);
const globalClickHandler = (event) => {
    // eslint-disable-next-line no-undef
    if (!(event.target instanceof Node)) {
        return;
    }
    const a = getAnchor(event.target);
    if (!a) {
        return;
    }
    const href = a.getAttribute('href');
    if (!href ||
        a.dataset.reactLink ||
        event.ctrlKey ||
        event.metaKey ||
        event.shiftKey ||
        event.button !== 0 ||
        isPageReloadScheduled()) {
        return;
    }
    const path = prependAppPathToEmbbeddedWicketPages(href);
    const isApp = path && path.indexOf('/app/') === 0;
    if (!isApp || event.defaultPrevented) {
        return;
    }
    event.preventDefault();
    defer(() => history.push(path.replace('/app', '')));
};
const useGlobalLinkClickListener = () => {
    useEffect(() => {
        document.addEventListener('click', globalClickHandler);
        return () => document.removeEventListener('click', globalClickHandler);
    }, []);
};
const useNavigationToggleState = () => {
    const [showNavigation, setShowNavigation] = useState(false);
    useEffect(() => {
        if (showNavigation) {
            const className = 'main-navigation-open';
            document.body.classList.add(className);
            const keyEvent = {
                onKey: (event) => {
                    if (keyEventStackService.isEsc(event)) {
                        setShowNavigation(false);
                        return false;
                    }
                },
            };
            keyEventStackService.register(keyEvent);
            const unlisten = history === null || history === void 0 ? void 0 : history.listen(() => setShowNavigation(false));
            return () => {
                document.body.classList.remove(className);
                keyEventStackService.deregister(keyEvent);
                unlisten === null || unlisten === void 0 ? void 0 : unlisten();
            };
        }
    }, [showNavigation]);
    return { showNavigation, setShowNavigation };
};
const App = () => {
    useGlobalLinkClickListener();
    useCompanyColorCSSVariable();
    const { showNavigation, setShowNavigation } = useNavigationToggleState();
    return (_jsxs(Router, { history: history, children: [_jsx(HeaderBroadcast, {}), _jsx(AppHeader, { toggleNavigation: () => setShowNavigation(!showNavigation) }), _jsx(TestDriveModeContainer, {}), _jsxs("div", { className: "main-wrapper-inner", children: [_jsx(Navigation, { closeNavigation: () => setShowNavigation(false) }), _jsx("main", { role: "main", className: "main-wrapper-content", children: _jsxs("div", { className: "page", "data-intercom-target": TOUR_INTRO.HOME_PAGE, children: [_jsxs("section", { className: "global_warnings", children: [_jsx(TrialExpiredWarning, {}), _jsx(LicenseWillExpireWarning, {})] }), _jsx(AppRoutes, {})] }) })] }), _jsx("aside", { id: "clippy", children: _jsx(ClippyTrigger, {}) }), _jsx(CustomBackdrop, {})] }));
};
export default App;
