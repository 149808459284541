// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SelectButton-selectButton-3IRKB {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 40px;\n  border: 1px solid #e0dfde;\n  border-radius: 5px;\n}\n.SelectButton-selectButton-3IRKB:hover {\n  background: #edeceb;\n  text-decoration: none;\n}\n.SelectButton-selectButton-3IRKB:active,\n.SelectButton-selectButton-3IRKB:focus {\n  text-decoration: none;\n  outline: none;\n  border-color: #0071d4;\n  box-shadow: 0 0 0 1px #0071d4;\n}\n.SelectButton-icon-31KDe {\n  font-size: 60px;\n  font-weight: 500;\n  width: auto !important;\n  --fi-stroke: var(--fi-stroke-m);\n}\n@media (max-width: 650px) {\n  .SelectButton-icon-31KDe {\n    font-size: 36px;\n  }\n}\n.SelectButton-si-content-width-max-m-ohi0n .SelectButton-icon-31KDe {\n  font-size: 36px;\n}\n.SelectButton-icon-31KDe.SelectButton-salmon-3wwUt {\n  color: #66b6ff;\n}\n.SelectButton-icon-31KDe.SelectButton-turquoise-136X1 {\n  color: #2ecd72;\n}\n.SelectButton-icon-31KDe,\n.SelectButton-title-3JvND,\n.SelectButton-description-3-Igw {\n  text-align: center;\n}\n.SelectButton-title-3JvND {\n  color: #1a1a1a;\n  font-size: 22px;\n  margin: 20px 10px;\n  --fi-size: 24px;\n}\n.SelectButton-description-3-Igw {\n  color: #7a7a79;\n}\n", ""]);
// Exports
exports.locals = {
	"selectButton": "SelectButton-selectButton-3IRKB",
	"icon": "SelectButton-icon-31KDe",
	"si-content-width-max-m": "SelectButton-si-content-width-max-m-ohi0n",
	"salmon": "SelectButton-salmon-3wwUt",
	"turquoise": "SelectButton-turquoise-136X1",
	"title": "SelectButton-title-3JvND",
	"description": "SelectButton-description-3-Igw"
};
module.exports = exports;
