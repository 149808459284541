import { map, mapValues, reduce } from 'lodash';
import { keyBy } from 'lodash/fp';
import moment from 'moment';
import { getDefaultExplanationReview, getDefaultName, getDefaultTimelineReview, getDefaultUnderReview, } from '../../../../components/CycleCreate/services';
import { getUsersForSelection } from '../../../../components/UserSelection/service';
import { getCurrentUser } from '../../../../services/CurrentUser';
import { isFeatureEnabled } from '../../../../services/Features';
import { getDefaultRatingsReview, getDefaultRatingsScale } from '../../../FeedbackBouquet/constants/section-templates';
import SECTION_TYPES from '../../../FeedbackBouquet/constants/sectionTypes';
import { areTemplateSectionsEqual, questionnaireToTemplateSections } from '../../../Reviews/services/templates';
import { templates } from '../../../Reviews/services/templates/templates';
import * as APPROVAL_PROCESS from '../../constants/approval-process';
import { getDefaultQuestionnaire } from '../defaultQuestionnaire';
import { getActiveRevieweeSelection, getRevieweeSelection } from './revieweeSelection';
const getUserMap = keyBy('id');
const EMPTY_CUSTOM_REVIEWERS = { mainReviewers: {}, contributingReviewers: {} };
function getDefaultReviewers(users) {
    const userMap = getUserMap(users);
    const reviewersMap = reduce(users, (reviewers, user) => {
        reviewers.mainReviewers[user.id] = userMap[user.managerId] || null;
        reviewers.contributingReviewers[user.id] = userMap[user.secondaryReviewerId] || null;
        return reviewers;
    }, EMPTY_CUSTOM_REVIEWERS);
    return reviewersMap;
}
export function getCreateModel(users = [], reviewees = [], emailTemplates, cyclesPerYear = {}) {
    const currentUser = getCurrentUser();
    const baseDate = moment
        .tz(currentUser.company.companySettings.timeZone)
        .hours(currentUser.company.companySettings.endOfBusiness)
        .subtract(1, 'day');
    const revieweeSelection = getRevieweeSelection(users, reviewees);
    return {
        users,
        reviewees: getUsersForSelection(users, getActiveRevieweeSelection(revieweeSelection)).map(u => u.id),
        revieweeSelection,
        selectedReviewers: getDefaultReviewers(users),
        cycleAdmins: [],
        name: getDefaultName('review'),
        explanation: getDefaultExplanationReview(),
        periodUnderReview: {
            from: getDefaultUnderReview().start,
            to: getDefaultUnderReview().end,
        },
        disabledDates: {},
        timeline: getDefaultTimelineReview(currentUser, baseDate),
        sharingAndApproval: {
            indirectManagerContribution: false,
            hrAndIndirectManagerApproval: APPROVAL_PROCESS.NOT_NECESSARY,
            showToRevieweeOnlyWhenBothShare: false,
            showToManagerOnlyWhenBothShare: false,
            individualAssessments: false,
        },
        questionnaire: getDefaultQuestionnaire(),
        emailTemplates,
        scheduleInformOnCycleStart: false,
        allowContentChangesWhileSharing: true,
        allowSettingOverallRatingBeforeCalibrationPeriod: false,
        allowOverallRatingChangesWhileSharing: true,
        allowOverallRatingChangesWhileCalibrating: true,
        notifyHrAdminsWhenManagersAreFinishedWriting: false,
        notifyIndirectManagersWhenManagersAreFinishedWriting: false,
        automations: {
            autoNudgeNonStarted: { enabled: false, weekday: 'MO', hourInCompanyTimeZone: 10 },
            autoNudgeNonShared: { enabled: false, weekday: 'MO', hourInCompanyTimeZone: 10 },
        },
        cycleType: 'SIMPLE', // CALIBRATION | SIMPLE
        cyclesPerYear,
    };
}
const TRANSFORMATORS = {
    questionnaire: transformQuestionnaire,
    selectedReviewers: transformSelectedReviewers,
};
export function getEditModel(model, users, emailTemplates, companyFrontendTemplates = [], cyclesPerYear) {
    const cycleType = model.isCalibration ? 'CALIBRATION' : 'SIMPLE';
    const defaultModel = getCreateModel(users, model.reviewees, emailTemplates, cyclesPerYear);
    const context = { userMap: getUserMap(users), timeline: model.timeline, cycleType, companyFrontendTemplates };
    return Object.assign(Object.assign({}, reduceWithTransformators(TRANSFORMATORS, model, defaultModel, context)), { emailTemplates, cycleType: model.isCalibration ? 'CALIBRATION' : 'SIMPLE' });
}
export function getCopyModel(model, users, emailTemplates, cyclesPerYear) {
    const editModel = getEditModel(model, users, emailTemplates, [], cyclesPerYear);
    if (isFeatureEnabled('FEATURE_reviewNewUserSelection')) {
        editModel.selectedReviewers = getDefaultReviewers(users);
    }
    return Object.assign(Object.assign({}, editModel), { name: `Copy of ${editModel.name}` });
}
function reduceWithTransformators(transformators, model, defaultModel, context) {
    return reduce(model, (mem, v, k) => {
        mem[k] = withTransformators(transformators, k, v, defaultModel[k], context);
        return mem;
    }, defaultModel);
}
function withTransformators(transformators, key, value, defaultValue, context) {
    const transformator = transformators[key];
    if (!transformator) {
        return value;
    }
    return transformator(value, defaultValue, context);
}
const QUESTIONNAIRE_TRANSFORMATORS = {
    graph: transformGraph,
    sections: transformSections,
    overallRatings: transformOverallRatings,
    secondaryOverallRatings: transformSecondaryOverallRatings,
};
function transformQuestionnaire(value, defaultValue, { cycleType, companyFrontendTemplates }) {
    const transformed = reduceWithTransformators(QUESTIONNAIRE_TRANSFORMATORS, value, defaultValue);
    const templateMatch = [...companyFrontendTemplates, ...templates(cycleType)].find(tpl => areTemplateSectionsEqual(tpl.sections, questionnaireToTemplateSections(transformed)));
    return Object.assign(Object.assign({}, transformed), { templateId: templateMatch ? templateMatch.id : undefined });
}
function transformGraph(graph, defaultGraph) {
    if (!graph.enabled) {
        return {
            enabled: graph.enabled,
            xAxis: defaultGraph.xAxis,
            yAxis: defaultGraph.yAxis,
        };
    }
    return graph;
}
function transformSections(sections) {
    function withTransformedRatings(sectionPart, questionType) {
        if (!sectionPart.ratings || !sectionPart.ratings.length) {
            return Object.assign(Object.assign({}, sectionPart), { ratingsEnabled: false, ratings: getDefaultRatingsReview(), scale: getDefaultRatingsScale() });
        }
        return Object.assign(Object.assign({}, sectionPart), { ratingsEnabled: questionType !== 'SCALES', ratings: map(sectionPart.ratings, transformRating), scale: map(sectionPart.ratings, transformRating) });
    }
    function transformRating(rating, i) {
        return { text: rating, id: `${i}` };
    }
    return map(sections, (section, i) => {
        var type = SECTION_TYPES.REVIEW_QUESTION;
        if (section.questionType === 'SCALES') {
            type = SECTION_TYPES.REVIEW_SCALE;
        }
        else if (!isFeatureEnabled('FEATURE_confidentialSections') &&
            section.confidentialTopic &&
            section.questionType === 'TEXT') {
            type = SECTION_TYPES.CONFIDENTIAL_QUESTION;
        }
        else if (section.questionType === 'HEADING') {
            type = SECTION_TYPES.REVIEW_HEADING;
        }
        else if (section.questionType === 'SLIDER') {
            type = SECTION_TYPES.REVIEW_SLIDER;
        }
        else if (section.questionType === 'CHECKBOXES') {
            type = SECTION_TYPES.REVIEW_CHECKBOXES;
        }
        else if (section.questionType === 'DROPDOWN') {
            type = SECTION_TYPES.REVIEW_DROPDOWN;
        }
        return Object.assign(Object.assign({}, section), { type: type, id: `${i}`, title: section.title, description: section.description, manager: withTransformedRatings(section.manager, section.questionType), reviewee: withTransformedRatings(section.reviewee, section.questionType) });
    });
}
function transformOverallRatings(overallRatings, defaultOverallRatings) {
    if (!overallRatings.enabledForManager && !overallRatings.enabledForReviewee) {
        return defaultOverallRatings;
    }
    return Object.assign(Object.assign({}, overallRatings), { enabled: true, ratingsDirty: false });
}
function transformSecondaryOverallRatings(overallRatings, defaultOverallRatings) {
    if (!overallRatings.enabledForManager && !overallRatings.enabledForReviewee) {
        return defaultOverallRatings;
    }
    return Object.assign(Object.assign({}, overallRatings), { enabled: true, ratingsDirty: false });
}
const SELECTED_REVIEWERS_TRANSFORMATORS = {
    contributingReviewers: transformContributingReviewers,
    mainReviewers: transformMainReviewers,
};
function transformSelectedReviewers(value, defaultValue, context) {
    return reduceWithTransformators(SELECTED_REVIEWERS_TRANSFORMATORS, value, defaultValue, context);
}
function transformContributingReviewers(value, defaultValue, context) {
    const mapped = mapValues(value, contributors => map(contributors, user => context.userMap[user])[0]);
    return Object.assign(Object.assign({}, defaultValue), mapped);
}
function transformMainReviewers(value, defaultValue, context) {
    const mapped = mapValues(value, reviewer => context.userMap[reviewer]);
    return Object.assign(Object.assign({}, defaultValue), mapped);
}
