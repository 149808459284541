import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import image from '../../../../../page/screens/registerPage/onboarding-survey-step-two-highlight.png';
import authorImage from '../../../../../page/screens/registerPage/tint-portrait-tim-sae-koo.jpg';
import api from '../../../../api';
import { Form } from '../../../components/Form';
import { FormErrorMessage } from '../../../components/Form/FormErrorMessage';
import { FormSelectSimple } from '../../../components/Form/FormInputs';
import { FormItem } from '../../../components/Form/FormItem';
import RegisterFlowTemplate from '../../../components/RegisterFlowTemplate';
import SaveButton from '../../../components/SaveButton';
import { goToOnboardingSurveyOriginStep } from '../../../services/GoTo';
import { VALIDATORS } from '../../../services/Validation';
import styles from '../styles.m.less';
const BUTTON_LABELS = {
    DISABLED: 'Continue',
    ENABLED: 'Continue',
};
const ROLES = ['HR Admin', 'Executive', 'Manager', 'Founder'];
export default function OnboardingSurveyDemographicStep() {
    const [isCompanySizeFilled, setIsCompanySizeFilled] = useState(false);
    useEffect(() => {
        api.onboardingSettings.getCompanySizeFilled().then(setIsCompanySizeFilled);
    }, []);
    const handleSubmit = async (formValues) => {
        return api.onboardingSettings
            .createOnboardingSettings(formValues)
            .then(goToOnboardingSurveyOriginStep)
            .catch(goToOnboardingSurveyOriginStep);
    };
    return (_jsx(RegisterFlowTemplate, { step: "2", title: "Finish your profile setup", imageURL: image, quote: "\u201CIt's a really great tool because you have everything in one place. I kid you not, I thought it was the godsend answer.\u201D", author: "Tim Sae Koo", role: "CEO at Tint", authorImage: authorImage, children: _jsx(Form, { onSubmit: handleSubmit, children: ({ submitError, invalid }) => (_jsxs(_Fragment, { children: [_jsx(FormItem, { name: "role", label: "What is your role?", inputComponent: FormSelectSimple, inputProps: {
                            placeholder: '',
                            options: ROLES.map(role => ({ value: role, label: role })),
                        }, validators: [VALIDATORS.required({ errorMessage: 'Please select a role.' })] }), !isCompanySizeFilled && (_jsx(FormItem, { name: "companySize", label: "What is your company size?", type: "number", validators: [
                            VALIDATORS.required({
                                errorMessage: 'Please enter your company size as a number.',
                            }),
                            VALIDATORS.number({ min: 1 }),
                        ], inputProps: { inputMode: 'decimal' } })), _jsxs("div", { className: styles.formButtonContainer, children: [_jsx(SaveButton, { state: 'ENABLED', labels: BUTTON_LABELS, disabled: invalid }), submitError ? _jsx(FormErrorMessage, { errorMessage: submitError }) : null] })] })) }) }));
}
