import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import api from '../../../../api';
import BookConsultation from '../../../components/BookConsultation';
import Button from '../../../components/Button';
import ButtonContainer from '../../../components/ButtonContainer';
import Modal from '../../../components/Modal';
import RegisterFlowTemplate from '../../../components/RegisterFlowTemplate';
import SaveButton from '../../../components/SaveButton';
import { useAppContext } from '../../../hooks/useAppContext';
import { goToDashboardPage } from '../../../services/GoTo';
import { initIntercom } from '../../../services/intercom';
import styles from '../styles.m.less';
import styles2 from './styles.m.less';
const MIN_SETUP_SECONDS = 100;
const BUTTON_LABELS = {
    SAVING: 'Setting up…',
    ENABLED: 'Go to Homepage',
};
export default function OnboardingSurveyDone() {
    const { user } = useAppContext();
    const [isDone, setIsDone] = useState(getTimeRemainingInMS(user.dateCreated) < 0);
    const [onboardingSettingsHris, setOnboardingSettingsHris] = useState(undefined);
    useEffect(() => {
        api.onboardingSettings.getOnboardingSettings().then(onboardingSettings => {
            if (onboardingSettings) {
                setOnboardingSettingsHris(onboardingSettings === null || onboardingSettings === void 0 ? void 0 : onboardingSettings.hris);
            }
        });
    }, []);
    const [showConsulation, setShowConsulation] = useState(user.company.server !== 'prod-eu');
    useEffect(() => {
        if (!isDone) {
            const timeoutId = setTimeout(() => {
                setIsDone(true);
            }, getTimeRemainingInMS(user.dateCreated));
            return () => clearTimeout(timeoutId);
        }
    }, [isDone, user.dateCreated]);
    const handleSaveButtonClick = () => {
        goToDashboardPage();
        if (onboardingSettingsHris) {
            initIntercom();
        }
    };
    return (_jsxs(RegisterFlowTemplate, { title: "You\u2019re all set!", illustration: _jsx(Illustration, {}), children: [_jsx(Consultation, { showConsulation: showConsulation, onCloseConsulation: () => {
                    setShowConsulation(false);
                } }), _jsxs("p", { children: ["Your trial includes a test company with sample cycles and access to the viewpoints of different employees including ", _jsx("em", { children: "Chris CEO" }), ", ", _jsx("em", { children: "Mary Manager" }), ", and ", _jsx("em", { children: "Salma Sales" }), ", so you can experience different perspectives in the tool. Once you\u2019re done with your trial, you can easily start using Small Improvements with your real team."] }), _jsx("div", { className: styles.formButtonContainer, children: _jsx(SaveButton, { state: isDone ? 'ENABLED' : 'SAVING', labels: BUTTON_LABELS, disabled: !isDone, type: "button", onClick: handleSaveButtonClick }) })] }));
}
function Consultation({ showConsulation, onCloseConsulation, }) {
    return (_jsxs(Modal, { isOpen: showConsulation, title: "Book a free consultation", onClose: onCloseConsulation, children: [_jsx("p", { className: styles2.consultationText, children: "Book a free consultation below with our team so we can better understand your unique needs around performance management." }), _jsx(BookConsultation, {}), _jsx(ButtonContainer, { children: _jsx(Button, { onClick: () => {
                        onCloseConsulation();
                    }, children: "Close" }) })] }));
}
function getTimeRemainingInMS(dateCreated) {
    return MIN_SETUP_SECONDS * 1000 - (Date.now() - dateCreated);
}
const ILLUSTRATION_USERS = [
    { id: 'zGAcXcrvQYKxVlNkEZAMqw', name: 'Chris CEO', logo: '/images/characters/chris.svg' },
    { id: 'a', name: 'Mary Manager', logo: '/images/characters/mary.svg' },
    { id: 'b', name: 'Sam Sales', logo: '/images/characters/sam.svg' },
    { id: 'c', name: 'Dan Developer', logo: '/images/characters/dan.svg' },
    { id: 'd', name: 'Heather HR', logo: '/images/characters/heather.svg' },
    { id: 'e', name: 'Irene Intern', logo: '/images/characters/irene.svg' },
    { id: 'f', name: 'Stan Sales', logo: '/images/characters/stan.svg' },
    { id: 'g', name: 'Daphne Developer', logo: '/images/characters/daphne.svg' },
    { id: 'h', name: 'Dave DevOps', logo: '/images/characters/dave.svg' },
];
function Illustration() {
    return (_jsxs(_Fragment, { children: [_jsx("h3", { className: styles2.illustrationHeading, children: "Your Test Company:" }), _jsx("div", { className: styles2.illustration, children: ILLUSTRATION_USERS.map(({ id, name, logo }) => (_jsxs("div", { className: styles2.illustrationCharacter, children: [_jsx("img", { alt: "", src: logo, className: styles2.illustrationCharacterImage }), _jsx("span", { children: name })] }, id))) })] }));
}
