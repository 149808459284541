const AXIS_LOW = 'poor';
const AXIS_HIGH = 'great';
export const getDefaultQuestionnaire = () => ({
    graph: {
        enabled: false,
        requiredForManager: false,
        requiredForReviewee: false,
        confidentialGraph: false,
        xAxis: {
            low: AXIS_LOW,
            high: AXIS_HIGH,
            label: 'Behavior',
            description: "Behavior: Rate the reviewed person's behavior and attitude towards coworkers and clients. Consider helpfulness, positivity, vision, and further so called soft factors.",
        },
        yAxis: {
            low: AXIS_LOW,
            high: AXIS_HIGH,
            label: 'Results',
            description: 'Results: How did the reviewed person perform? Take into account her objectives, capabilities and seniority.',
        },
    },
    graphInEditMode: null,
    sections: [],
    sectionsInEditMode: [],
    sectionsInEditNeverSaved: [],
    overallRatings: {
        enabled: false,
        enabledForManager: true,
        enabledForReviewee: true,
        requiredForManager: false,
        requiredForReviewee: false,
        inEditMode: false,
        confidential: false,
        ratings: [
            {
                code: '5',
                label: '5 - Exceeds expectations by far',
                description: 'The employee achieved truly amazing results.',
                value: 1,
            },
            {
                code: '4',
                label: '4 - Exceeds expectations, outstanding job',
                description: ' The employee is working well above what is expected from most people in this position, seniority, and salary band.',
                value: 2,
            },
            {
                code: '3',
                label: '3 - Meets expectations, great job',
                description: 'The employee is doing a great job, and is occasionally exceeding expectations, while occasionally there is room for improvement.',
                value: 3,
            },
            {
                code: '2',
                label: '2 - Below expectations',
                description: 'The employee is struggling to meet expectations. Sometimes the expectations are met, but there is a strong need for improvement.',
                value: 4,
            },
            {
                code: '1',
                label: '1 - Insufficient',
                description: 'The employee is not meeting expectations for the role. The situation needs to improve significantly.',
                value: 5,
            },
        ],
    },
    secondaryOverallRatings: {
        enabled: false,
        enabledForManager: true,
        enabledForReviewee: false,
        requiredForManager: false,
        requiredForReviewee: false,
        inEditMode: false,
        ratings: [
            {
                code: '5',
                label: '5 - Exceeds expectations by far',
                description: 'The employee achieved truly amazing results.',
                value: 1,
            },
            {
                code: '4',
                label: '4 - Exceeds expectations, outstanding job',
                description: ' The employee is working well above what is expected from most people in this position, seniority, and salary band.',
                value: 2,
            },
            {
                code: '3',
                label: '3 - Meets expectations, great job',
                description: 'The employee is doing a great job, and is occasionally exceeding expectations, while occasionally there is room for improvement.',
                value: 3,
            },
            {
                code: '2',
                label: '2 - Below expectations',
                description: 'The employee is struggling to meet expectations. Sometimes the expectations are met, but there is a strong need for improvement.',
                value: 4,
            },
            {
                code: '1',
                label: '1 - Insufficient',
                description: 'The employee is not meeting expectations for the role. The situation needs to improve significantly.',
                value: 5,
            },
        ],
    },
    maximumCharactersPerResponse: 50000,
});
