import { jsx as _jsx } from "react/jsx-runtime";
import Button from '../../react/components/Button';
import WithHrListSupportModal from '../../react/features/Navigation/SupportLink/WithHrListSupportModal';
import { ErrorCard, RouteErrorPlain } from './plain';
export default function RouteError(props) {
    return _jsx(RouteErrorPlain, Object.assign({}, props, { renderInfoButton: code => code === 403 && _jsx(AdminsModal, {}) }));
}
function AdminsModal() {
    return (_jsx(WithHrListSupportModal, { children: ({ openModal }) => _jsx(Button, { onClick: openModal, children: "See Admins" }) }));
}
export { ErrorCard };
